import React from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';

import emptyImgIcon from '../../img/empty_img.svg';

const EmptyImg: React.FC = () => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <img src={emptyImgIcon} alt="" className={classes.img} />
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff9f7',
    },
    img: {
        width: 68,
        height: 68,
    },
}));

export default EmptyImg;
