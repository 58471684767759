// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { observable, action, runInAction } from 'mobx';
import { api } from '../api';
import SocialAuthService from '../services/SocialAuthService';
import { IResetAndSetPassword, ISignInEmail, ISignUpEmail, Provider } from '../types/AuthTypes';
import { showNotification } from '../utils/showNotification';

export const _tokenStorageKey = 'huzurAccessToken';

export class AuthStore {
    @observable loading = false;
    @observable loadingSocial = false;

    @observable errorMessages: any = {};
    @observable confirmEmailMessage = false;

    @observable token = null;
    @observable isAuth = false;

    @observable completeCheckAuth = false;

    @observable provider: Provider | null = null;
    @observable socialAuthUrl: string | null = null;

    @action
    register = (data: ISignUpEmail) => {
        this.loading = true;

        const [surname = '', name = '', middleName = ''] = data.name.split(' ');
        const registerData = {
            email: data.email,
            surname,
            name,
            middleName,
            password: data.password,
            password_confirmation: data.confirmPassword,
        };

        api.post('/auth/register', registerData)
            .then(response => {
                if (response.data.success) {
                    runInAction(() => {
                        this.confirmEmailMessage = true;
                    });
                }
            })
            .catch(error => {
                if (error.response.data?.errors) {
                    runInAction(() => {
                        this.errorMessages = error.response.data.errors;
                    });
                }
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    };

    @action
    login = (data: ISignInEmail, history: any) => {
        this.loading = true;

        api.post('/auth/login', data)
            .then(response => {
                if (response.data.success) {
                    const token = response.data.data.token;

                    this.setAuth(token);
                    history.push('/profile');
                }
            })
            .catch(error => {
                if (error.response.data?.errors) {
                    runInAction(() => {
                        this.errorMessages = error.response.data.errors;
                    });
                }
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    };

    @action
    getSocialAuthUrl = (provider: Provider) => {
        this.loadingSocial = true;

        api.get(`/auth/social-auth/${provider}`)
            .then(response => {
                if (response.data.success) {
                    if (response.data.message) {
                        const url = response.data.message;
                        runInAction(() => {
                            this.provider = provider;
                        });
                        SocialAuthService.openAuthWindow(url);
                    }
                }
            })
            .catch(error => {
                if (error.response?.data?.errors) {
                    runInAction(() => {
                        this.errorMessages = error.response.data.errors;
                    });
                }
            })
            .finally(() => {
                runInAction(() => {
                    this.loadingSocial = false;
                });
            });
    };

    @action
    socialLogin = (code: string, history: any) => {
        this.loadingSocial = true;

        if (this.provider) {
            api.get(`/auth/social-auth?provider=${this.provider}&code=${code}`)
                .then(response => {
                    if (response.data.success) {
                        const token = response.data.data.token;

                        runInAction(() => {
                            this.setAuth(token);
                            history.push('/profile');
                        });
                    }
                })
                .catch(error => {
                    if (error.response.data?.errors) {
                        runInAction(() => {
                            this.errorMessages = error.response.data.errors;
                        });
                    }
                })
                .finally(() => {
                    runInAction(() => {
                        this.clearProvider();
                    });
                });
        }
    };

    @action
    logout = (history: any) => {
        this.loading = true;

        api.delete('/auth/logout')
            .then(response => {
                if (response.data.success) {
                    api.clearToken();
                    localStorage.removeItem(_tokenStorageKey);
                    runInAction(() => {
                        this.isAuth = false;
                    });

                    // Redirect
                    history.push('/');

                    showNotification('success', response.data?.message || '');
                }
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    };

    @action
    resendConfirmLink = () => {
        this.loading = true;

        api.post('/auth/email/resend', {})
            .then(response => {
                if (response.data?.success) {
                    showNotification('success', response.data?.message || '', 'bottom-center');
                }
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    };

    @action
    resetPassword = (email: string) => {
        this.loading = true;

        api.post('/auth/password/email', { email })
            .then(response => {
                if (response.data.success) {
                    runInAction(() => {
                        this.confirmEmailMessage = true;
                    });
                }
            })
            .catch(error => {
                if (error.response.data?.errors) {
                    runInAction(() => {
                        this.errorMessages = error.response.data.errors;
                    });
                }
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    };

    @action
    saveNewPassword = (values: IResetAndSetPassword, history: any) => {
        this.loading = true;

        const data = {
            email: values.email,
            password: values.newPassword,
            password_confirmation: values.confirmNewPassword,
            token: values.token,
        };

        api.post('/auth/password/reset', data)
            .then(response => {
                if (response.data.success) {
                    runInAction(() => {
                        showNotification('success', response.data.message || '');
                        history.replace('/login/email');
                    });
                }
            })
            .catch(error => {
                if (error.response.data?.errors) {
                    runInAction(() => {
                        this.errorMessages = error.response.data.errors;
                    });
                }
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    };

    @action
    checkAuth = () => {
        const token = JSON.parse(localStorage.getItem(_tokenStorageKey) || 'null');

        if (token) {
            api.setToken(token);
            this.completeCheckAuth = true;
            this.isAuth = true;

            return true;
        }

        this.isAuth = false;
        this.completeCheckAuth = true;

        return false;
    };

    @action
    setAuth = (token: string) => {
        api.setToken(token);
        localStorage.setItem(_tokenStorageKey, JSON.stringify(token));

        runInAction(() => {
            this.isAuth = true;
        });
    };

    @action
    resetStore = () => {
        api.clearToken();
        localStorage.removeItem(_tokenStorageKey);
        this.isAuth = false;

        this.errorMessages = {};
    };

    @action
    clearConfirmEmailMessage = () => {
        this.confirmEmailMessage = false;
    };

    @action
    clearErrors = () => {
        this.errorMessages = {};
    };

    @action
    clearProvider = () => {
        this.loadingSocial = false;
        this.provider = null;
    };
}
