import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import { Container, Box, Typography, Button, makeStyles, Theme, Divider, Grid, useTheme, useMediaQuery } from '@material-ui/core';

import { useStores } from '../hooks/useStores';
import { ITestQuestionResult } from '../types/LessonTestTypes';
import { transformNormalContentText } from '../utils/transformNormalContentText';
import TestQuestionResult from '../components/LessonTest/TestQuestionResult';
import { scrollTo } from '../utils/scrollTo';
import { ISubjectLesson } from '../types/SubjectTypes';
import { useTranslation } from 'react-i18next';

const LessonTestResult: React.FC = () => {
    const { LessonTestStore: store, SubjectStore } = useStores();
    const classes = useStyles();
    const history = useHistory();
    const { subjectId, lessonId } = useParams<{ subjectId: any, lessonId: any }>();
    const isPassed = store.result?.isPassed;
    const { t } = useTranslation();

    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));

    // Effects
    useEffect(() => {
        scrollTo();
    }, []);

    useEffect(() => {
        return () => {
            store.clearResultData();
        };
    }, [store]);

    // Effects
    useEffect(() => {
        if (store.result?.isPassed) {
            SubjectStore.getPrograms(subjectId);
            return () => {
                SubjectStore.resetPrograms();
            };
        }
    }, [SubjectStore, store.result, subjectId]);

    // Handlers
    const handleGoToNextLesson = () => {
        if (SubjectStore.programsData) {
            const lessons = SubjectStore.programs.flatMap(a => a.lessons);
            const nextLessonIndex = lessons.findIndex((lesson: ISubjectLesson) => lesson.status === 'available');

            if (nextLessonIndex !== -1) {
                const nextLessonId = lessons[nextLessonIndex]?.id;

                history.push(`/subjects/${subjectId}/lesson/${nextLessonId}`);
            } else {
                // Congrats! All lessons is done
                history.push('/subjects');
            }
        }
    };

    if (!store.result) {
        return <Redirect to={`/subjects/${subjectId}/lesson/${lessonId}`} />;
    }

    // Render
    const renderButtons = () => {
        if (isPassed) {
            return (
                <Grid item xs={12} sm={6} md={4}>
                    <Button fullWidth variant="contained" color="primary" onClick={handleGoToNextLesson}>
                        {t("LessonsGoToNext")}
                    </Button>
                </Grid>
            );
        } else {
            return (
                <>
                    <Grid item xs={12} sm={6} md={4}>
                        <Link to={`/subjects/${subjectId}/lesson/${lessonId}`} className="router-link">
                            <Button fullWidth variant="outlined" color="primary">
                                {t("LessonsGoToBack")}
                            </Button>
                        </Link>
                    </Grid>
                </>
            );
        }
    };

    return (
        <Container maxWidth="lg">
            <Box display="flex" justifyContent="center" mt={5} mb={{ xxs: 4, md: 6 }}>
                <Box className={clsx(classes.resultBox, !isPassed ? classes.notPassed : '')}>
                    <Box mb={{ xxs: 1, md: 2 }}>
                        <Typography>{t("LessonsResult")}</Typography>
                    </Box>
                    <Typography variant="h2">
                        {store.result?.totalCorrect}/{store.result?.totalQuestions}
                    </Typography>
                </Box>
            </Box>

            <Box mb={2} textAlign="center">
                <Typography variant="h5">
                    {isPassed ? t("LessonsCongrads") : t("LessonsSorry")}
                </Typography>
            </Box>
            <Box mb={{ xxs: 5, md: 6 }} textAlign="center">
                <Typography
                    color="textSecondary"
                    component="div"
                    dangerouslySetInnerHTML={{ __html: transformNormalContentText(store.testData?.description || '') }}
                ></Typography>
            </Box>

            {store.result?.wrongQuestions.length ? (
                <>
                    <Box mb={{ xxs: 3, md: 4 }}>
                        <Typography variant="h5">{t("LessonsWrongAnswers")}</Typography>
                    </Box>
                    <Box mb={8}>
                        {store.result?.wrongQuestions.map((question: ITestQuestionResult, index: number) => (
                            <>
                                <TestQuestionResult question={question} index={index + 1} />
                                {store.result?.wrongQuestions.length !== index + 1 && (
                                    <Box my={3} ml={8}>
                                        <Divider />
                                    </Box>
                                )}
                            </>
                        ))}
                    </Box>
                </>
            ) : (
                <Box mb={8}>&nbsp;</Box>
            )}

            <Box mt={-4} mb={{ xxs: 5, sm: 9 }}>
                <Grid container spacing={isXS ? 2 : 4} justifyContent="center">
                    {renderButtons()}
                </Grid>
            </Box>
        </Container>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    resultBox: {
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: 350,
        padding: theme.spacing(3),
        borderRadius: 24,
        background: '#E0F2F1',
        [theme.breakpoints.down('sm')]: {
            minWidth: 290,
            padding: theme.spacing(2),
            paddingBottom: theme.spacing(1),
        },
    },
    notPassed: {
        background: '#F7A1A1',
    },
}));

export default LessonTestResult;
