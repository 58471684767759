import React from 'react';
import { useTranslation } from 'react-i18next';
import SocialBtn from './SocialBtn';

interface ISocialBtnsListProps {
    type: 'signin' | 'signup';
}

const SocialBtnsList: React.FC<ISocialBtnsListProps> = ({ type }) => {
    const { t } = useTranslation();
    
    return (
        <>
            <SocialBtn provider="google">{t("SocialBtnsListGoogle")}</SocialBtn>
            <SocialBtn provider="vkontakte">{t("SocialBtnsListVkontakte")}</SocialBtn>
            <SocialBtn provider="odnoklassniki">{t("SocialBtnsListOdnoklassniki")}</SocialBtn>
            <SocialBtn provider="email">{t("SocialBtnsListEmail")}</SocialBtn>
        </>
    );
};

export default SocialBtnsList;
