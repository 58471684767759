import React from 'react';
import { Container, Box, makeStyles, Theme, useTheme, useMediaQuery, Typography } from '@material-ui/core';
import { isRu } from '../utils/isRu';

const PrivacyPolicy = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));
    
    return isRu ? (
        <Container maxWidth="xl">
            <Box my={{ xxs: 4, lg: 6 }}>
                <Box mt={4} mb={6}>
                    <Typography variant={isXS ? 'h5' : 'h4'}>Политика конфиденциальности</Typography>
                </Box>
                <Box className={classes.content}>
                    <p>
                    Принципы защиты персональных данных в компании ИД <strong>&laquo;Хузур&raquo;.</strong>
                    </p>
                    <p>
                    Компания ИД &laquo;Хузур&raquo; очень серьезно относится к защите персональных данных. Следующие пять принципов лежат в основе нашего подхода к конфиденциальности:
                    </p>
                    <ol>
                        <li>
                        Мы ценим доверие, которое Вы оказываете нам, предоставляя Вашу личную информацию. Мы всегда будем использовать Вашу личную информацию честным и достойным доверия путем.
                        </li>
                        <li>
                        Вы имеете право получить достоверную информацию о том, как мы используем Ваши персональные данные. Для Вас должно всегда быть понятным то, какую информацию мы собираем, что мы с ней делаем, с кем мы делимся ею, и к кому следует обратиться, если у Вас возникли какие-либо проблемы.
                        </li>
                        <li>
                        Если у Вас возникли какие-либо вопросы о том, как мы используем Вашу личную информацию, мы будем рады ответить на них.
                        </li>
                        <li>
                        Мы предпримем все необходимые меры для защиты личной информации от неправомерного использования и сохраним ее в безопасном месте.
                        </li>
                        <li>
                        Мы будем соблюдать все действующие законы и правила о защите данных и сотрудничать со службами по защите данных. При отсутствии законодательства о защите данных, мы будем действовать в соответствии с общепринятыми принципами защиты данных.
                        </li>
                    </ol>

                    <p>
                        <strong>
                        Политика конфиденциальности в компании ИД &laquo;Хузур&raquo; 
                        </strong>
                    </p>
                    <p>
                    Компания ИД &laquo;Хузур&raquo; стремится сохранить конфиденциальность Ваших личных данных и обеспечить защиту Вашей личной информации. Данная Политика конфиденциальности определяется тем, какие типы личной информации мы собираем, как мы ее используем, раскрываем и защищаем.
                    </p>
                    <p>
                        <strong>
                        Область применения политики конфиденциальности компании ИД &laquo;Хузур&raquo;
                        </strong>
                    </p>
                    <p>
                        Конфиденциальлек сәясәте &laquo;Хузур&raquo; нәшрият йортының без тәкъдим иткән хезмәтләргә бәйле рәвештә җыйган
                        шәхси мәгълүматка тарала. Medrese.tatar сайты аша онлайн режимында җыелган мәгълүмат турында сүз бара.
                    </p>
                    <p>
                    Политика конфиденциальности распространяется на личную информацию, собранную ИД «Хузур», в связи с услугами, которые мы предлагаем. Эта информация включает в себя данные, собранные в режиме, а также в режиме онлайн через наши веб-сайты: Портал «Онлайн-медресе»- medrese.tatar, сайт ИД «Хузур» <a href="http://huzur.ru/">
                            <span>http://huzur.ru/</span>
                        </a> и других сайтов, находящихся в ведении ИД «Хузур». Полный список сайтов можно найти на главной странице сайта&nbsp;
                        <a href="http://huzur.ru/">
                            <span>http://huzur.ru/</span>
                        </a>
                        .&nbsp;Политика конфиденциальности является неотъемлемой частью правил и условий использования веб-сайта компании ИД «Хузур». Дополнительную информацию о компании ИД «Хузур», которая несет ответственность за защиту личной информации, Вы можете найти в разделе Правовая информаци <a href="http://huzur.ru/legal">
                            <span>http://huzur.ru/legal</span>
                        </a>
                    </p>
                    <p>Политика конфиденциальности распространяется на:</p>
                    <ul>
                        <li>информацию, собранную со сторонних веб-сайтов, платформ и / или приложений, которые мы не контролируем;</li>
                        <li>
                        информацию, собранную на сайтах, принадлежащим третьим лицам, доступ к которым Вы получили, перейдя по ссылке на сайтах компании ИД «Хузур»;
                        </li>
                        <li>
                        баннеры или другие объявления и рекламные акции, которые мы можем финансировать или участвовать на сайтах третьих лиц.
                        </li>
                    </ul>
                    <p>
                    Сторонние сайты могут иметь свою собственную политику конфиденциальности, условия и правила использования информации. Мы рекомендуем Вам ознакомиться с ними, прежде чем пользоваться сайтами третьих лиц.
                    </p>
                    <p>
                        <strong>Ваше согласие</strong>
                    </p>
                    <p>
                    Мы не будем собирать, использовать или раскрывать Вашу личную информацию без Вашего согласия. Пользуясь порталом «Онлайн-медресе» и другими сайтами компании ИД «Хузур», Вы тем самым даете согласие на сбор, использование и раскрытие Вашей личной информации компанией ИД «Хузур» в соответствии с данной Политикой конфиденциальности.
                    </p>
                    <p>
                    Мы можем дополнительно просить Вашего согласия на использование Вашей личной информации для целей, на которые данная Политика конфиденциальности не распространяется. Вы не обязаны соглашаться, но, если Вы решите не давать своего согласия, Ваше участие в определенных мероприятиях может быть ограничено. Если Вы даете свое согласие на дополнительные условия, они должны превалировать в случае их расхождения с условиями настоящей Политики конфиденциальности.
                    </p>
                    <p>
                    Если Вы не согласны на сбор, использование и раскрытие Вашей личной информации таким образом, пожалуйста, не используйте сайты компании ИД «Хузур» или предоставьте личную информацию компании ИД «Хузур» другим способом.
                    </p>
                    <p>
                        <strong>Как мы используем полученную информацию?</strong>
                    </p>
                    <p>Мы можем использовать информацию о Вас для того, чтобы:</p>
                    <ul>
                        <li>улучшить качество наших продуктов и работу сайтов компании ИД «Хузур»;</li>
                        <li>оценить использование сайтов, продуктов и услуг ИД «Хузур»;</li>
                        <li>оценить эффективность нашей рекламы;</li>
                        <li>
                        индивидуализировать Вашу работу с нашим сайтом и составить анонимную статистику поведения посетителя на сайте, включая время посещение сайта, предыдущие посещения сайта, адрес сайта, который отослал Вас на сайт компании ИД «Хузур» и т.п.;
                        </li>
                        <li>упростить использование сайтов ИД «Хузур»;</li>
                        <li>
                        ускорить работу с сайтом в будущем. Например, сайт распознает, что Вы предоставили личную информацию ранее, и не будет запрашивать ту же информацию во второй раз;
                        </li>
                        <li>
                        собрать информацию об используемом Вами устройстве для просмотра сайтов компании ИД «Хузур», включая IP-адрес, тип интернет-браузера или операционной системы, и связать ее с Вашей личной информацией для того, чтобы обеспечить самый высокий уровень работы в сети.
                        </li>
                    </ul>
                    <p>
                        <strong>Распространение личной информации</strong>
                    </p>
                    <p>
                    Мы не распространяем Вашу личную информацию за рамками компании ИД «Хузур». Однако, передача Ваших личных данных третьим сторонам, возможна, если это:
                    </p>
                    <ul>
                        <li>
                        Правоохранительные органы либо государственные власти, ведущие расследование в рамках судебного процесса, требующего от нас раскрытия информации.
                        </li>
                        <li>
                        Третьи стороны, желающим отправлять Вам информацию о своих товарах и услугах, но только в случае получения нами Вашего согласия.
                        </li>
                        <li>
                        Сайты компании ИД «Хузур» могут использоваться провайдерами Интернет, хостерами сайтов для сбора собственной информации о посетителях сайтов.
                        </li>
                    </ul>
                    <p>
                    Мы также можем передавать Ваши личные данные сторонним компаниям, организациям или отдельным лицам, если посчитаем раскрытие данной информации необходимым по причинам юридического характера.
                    </p>
                    <p>
                        <strong>Обеспечение защиты конфиденциальной информации.</strong>
                    </p>
                    <p>
                    Мы предпринимаем все разумные меры предосторожности для сохранности Ваших личных данных и требуем того же от третьих лиц, которые анализируют или обрабатывают ваши персональные данные для нас. Доступ к Вашим личным данным ограничен в целях предотвращения несанкционированного доступа, изменения или неправомерного использования, и разрешен только для наших сотрудников и агентов на основе принципа служебной необходимости.
                    </p>
                    <p>
                        <strong>Изменения в политике конфиденциальности</strong>
                    </p>
                    <p>
                    Последние изменения в политике конфиденциальности компании ИД «Хузур» – 31.12.2021.
                    </p>
                    <p>
                    По мере необходимости мы можем изменить Политику конфиденциальности путем размещения ее обновленной версии на сайтах компании ИД «Хузур». Мы призываем Вас как можно чаще посещать наши ресурсы, чтобы быть в курсе того, как мы используем вашу личную информацию.
                    </p>
                    <p>
                    В качестве дополнения к данной Политике конфиденциальности могут существовать отдельные кампании и рекламные акции на сайтах компании ИД «Хузур», которые будут регулироваться дополнительными условиями конфиденциальности или политикой. Мы рекомендуем Вам ознакомиться с этими дополнительными условиями или политикой, прежде чем принимать в них участие, так как Вы будете обязаны их соблюдать. Любые дополнительные условия конфиденциальности или политики будут находиться в открытом доступе.
                    </p>

                    <Box textAlign="right">
                        <p>
                            <em>С уважением,</em>
                        </p>
                        <p>
                            <em> Издательский Дом «Хузур» </em>
                            <a href="http://huzur.ru/">
                                <span>
                                    <em>http://huzur.ru/</em>
                                </span>
                            </a>
                        </p>
                        <p>
                            <em>Компания-разработчик Онлайн - медресе </em>
                            <a href="http://medrese.tatar">
                                <span>
                                    <em>http://medrese.tatar</em>
                                </span>
                            </a>
                        </p>
                    </Box>
                </Box>
            </Box>
        </Container>
    ) : (
        <Container maxWidth="xl">
            <Box my={{ xxs: 4, lg: 6 }}>
                <Box mt={4} mb={6}>
                    <Typography variant={isXS ? 'h5' : 'h4'}>Конфиденциальлек сәясәте</Typography>
                </Box>
                <Box className={classes.content}>
                    <p>
                        <strong>&laquo;Хозур&raquo; Нәшрият йортында шәхси мәгълүматны саклау принциплары.</strong>
                    </p>
                    <p>
                        &laquo;Хозур&raquo; Нәшрият Йорты шәхси мәгълүматны саклауга бик җаваплы карый. Түбәндә тәкъдим ителгән биш принцип
                        конфиденциальлек сәясәтенең нигезе булып тора:
                    </p>
                    <ol>
                        <li>
                            Үзегез турындагы мәгълүматны биреп, безгә күрсәткән ышанычыгызны югары бәялибез. Сезнең шәхси мәгълүматны без
                            һәрвакыт намуслы һәм лаеклы юл белән файдаланачакбыз.
                        </li>
                        <li>
                            Сезнең шәхси мәгълүматны ничек куллануыбыз турында тулы һәм дөрес мәгълүмат алырга хакыгыз бар. Безнең нинди
                            мәгълүмат җыюыбыз, аның белән нәрсә эшләвебез, кем белән уртаклашуыбыз, әгәр сездә нинди дә булса авырлыклар
                            туса, кемгә мөрәҗәгать итәргә кирәклеге Сезгә һәрвакыт аңлаешлы булырга тиеш.
                        </li>
                        <li>
                            Әгәр сезнең шәхси мәгълүматны ничек куллануыбыз турында сорауларыгыз булса, сорауларга җавап бирергә шат
                            булачакбыз.
                        </li>
                        <li>
                            Шәхси мәгълүматны хокуксыз файдаланудан яклау өчен, барлык кирәкле чараларны күрәбез һәм аны куркынычсыз урында
                            саклыйбыз.
                        </li>
                        <li>
                            Без шәхси мәгълүматларны яклау турындагы гамәлдәге барлык кануннарны һәм кагыйдәләрне үтибез һәм мәгълүматны
                            яклау хезмәтләре белән хезмәттәшлек итәчәкбез. Мәгълүматларны яклау турында кануннар булмаган очракта, без
                            мәгълүматны яклауның гомуми кабул ителгән принциплары нигезендә эш итәчәкбез.
                        </li>
                    </ol>

                    <p>
                        <strong>
                            &laquo;Хозур&raquo; Нәшрият йортында<span>&nbsp; </span>конфиденциальлек сәясәте
                        </strong>
                    </p>
                    <p>
                        &laquo;Хозур&raquo; Нәшрият Йорты<span>&nbsp; </span>компаниясе Сезнең шәхси мәгълүматны һәм шәхси мәгълүмат турында
                        конфиденциальлекне сакларга омтыла. Конфиденциальлек сәясәте безнең нинди төр шәхси мәгълүмат туплавыбыз, аны ни
                        рәвешле куллануыбыз һәм саклавыбызга бәйле.
                    </p>
                    <p>
                        <strong>
                            &laquo;Хозур&raquo; Нәшрият йортында<span>&nbsp; </span>конфиденциальлек сәясәтенең кулланылу өлкәсе
                        </strong>
                    </p>
                    <p>
                        Конфиденциальлек сәясәте &laquo;Хозур&raquo; нәшрият йортының без тәкъдим иткән хезмәтләргә бәйле рәвештә җыйган
                        шәхси мәгълүматка тарала. Medrese.tatar сайты аша онлайн режимында җыелган мәгълүмат турында сүз бара.
                    </p>
                    <p>
                        Конфиденциальлек сәясәте &laquo;Хозур&raquo; Нәшрият Йорты компаниясенең веб-сайтыннан файдалану кагыйдәләренең һәм
                        шартының аерылгысыз өлеше булып тора. Шәхси мәгълүматны саклау өчен җавап бирә торган &laquo;Хозур&raquo; Нәшрият
                        Йорты компаниясе турында тулырак мәгълүматны Сез &laquo;Хокукый мәгълүмат&raquo; бүлегеннән таба аласыз:{' '}
                        <a href="http://huzur.ru/legal">
                            <span>http://huzur.ru/legal/</span>
                        </a>
                    </p>
                    <p>Конфиденциальлек сәясәте түбәндәге өлкәләргә кагыла:</p>
                    <ul>
                        <li>без контрольләми торган чит веб-сайт, платформа һәм / яки кушымталар аша җыелган мәгълүмат;</li>
                        <li>
                            &laquo;Хозур&raquo; Нәшрият Йорты компаниясенең сайтында тәкъдим ителгән сылтамалар аша узып өченче затларга
                            караган сайтта калдырылган мәгълүмат;
                        </li>
                        <li>
                            өченче затларга караган сайтларда урнаштырылган һәм безнең тарафтан финансланган баннер һәм башка реклама
                            акцияләре.
                        </li>
                    </ul>
                    <p>
                        Чит сайтларның үз конфиденциальлек сәясәте, мәгълүматны куллану кагыйдәләре һәм шартлары була ала. Өченче затларга
                        караган сайтларда эшли башлаганчы, без Сезгә аларның конфиденциальлек сәясәте белән танышырга тәкъдим итәбез.
                    </p>
                    <p>
                        <strong>Сезнең ризалыгыгыз</strong>
                    </p>
                    <p>
                        Без Сезнең ризалыктан башка Сезнең шәхси мәгълүматыгызны җыймыйбыз. Кулланмыйбыз һәм ачмыйбыз. Онлайн мәдрәсә
                        порталын кулланып, Сез &laquo;Хозур&raquo; нәшрияты йорты компаниясенә Конфиденциальлек сәясәте нигезендә үзегез
                        турында шәхси мәгълүмат җыярга, кулланырга һәм ачарга рөхсәт бирәсез.
                    </p>
                    <p>
                        Без сезнең шәхси мәгълүматыгызны әлеге конфиденциальлек сәясәте таралмый торган максатлар өчен файдалануыгызны
                        өстәмә рәвештә сорый алабыз. Сез ризалашырга тиеш түгел, әмма сез ризалык бирмәскә уйласагыз, сезнең билгеле бер
                        чараларда катнашуыгыз чикләнергә мөмкин. Әгәр дә сез өстәмә шартларга үз ризалыгыгызны бирәсез икән, алар әлеге
                        конфиденциальлек сәясәтеннән аерылган очракта өстенлек итәргә тиеш.
                    </p>
                    <p>
                        Әгәр дә сез шәхси мәгълүматны җыюга, куллануга һәм ачуга риза булмасагыз, зинһар өчен, &laquo;Хозур&raquo;
                        компаниясенең сайтларын кулланмагыз яки &laquo;Хозур&raquo; компаниясенең шәхси мәгълүматны башка ысул белән
                        бирегез.
                    </p>
                    <p>
                        <strong>Алынган мәгълүматны ничек кулланабыз?</strong>
                    </p>
                    <p>Сезнең турындагы мәгълүматны түбәндәге очракларда куллана алабыз:</p>
                    <ul>
                        <li>Безнең продуктларның сыйфатын һәм &laquo;Хозур&raquo; компаниясе сайтларының эшен яхшырту өчен;</li>
                        <li>&laquo;Хозур&raquo; нәшрият йорты сайтларын, продуктларын һәм хезмәтләрен куллануны бәяләү өчен;</li>
                        <li>безнең рекламаның нәтиҗәлелеген бәяләү өчен;</li>
                        <li>
                            Сезнең сайт белән эшегезне индивидуаль рәвештә көйләү һәм сайтка килүченең үз-үзен тотышының аноним
                            статистикасын төзү, шул исәптән, сайтка керү вакытын, сайтка алдагы керү вакытын, сезне &laquo;Хозур&raquo;
                            Нәшрият Йорты компаниясе сайтына җибәрүче сайт адресын билгеләү өчен;
                        </li>
                        <li>&laquo;Хозур&raquo; Нәшрият Йорты сайтларын файдалануны гадиләштерү өчен;</li>
                        <li>
                            киләчәктә сайт белән эшне тизләтү өчен. Мәсәлән, сайт Сезнең моңарчы мәгүлүматны истә калдыра һәм икенче тапкыр
                            сорамый;
                        </li>
                        <li>
                            &laquo;Хәзур&raquo; нәшрият йорты компаниясенең сайтларын, шул исәптән IP-адресын, интернет-браузер яки операция
                            системасы тибын карап чыгу өчен, Сез файдалана торган җайланма турында мәгълүмат җыю һәм аны челтәрдә иң югары
                            эш дәрәҗәсен тәэмин итү өчен сезнең шәхси мәгълүмат белән бәйләү өчен.
                        </li>
                    </ul>
                    <p>
                        <strong>Шәхси мәгълүматның таралуы</strong>
                    </p>
                    <p>
                        Сезнең шәхси мәгълүматны &laquo;Хозур&raquo; Нәшрият Йорты чикләрендә таратмыйбыз. Ләкин Сезнең шәхси мәгълүматны
                        өченче затларга бирү очраклары каралган. Түбәндәге очраклар:
                    </p>
                    <ul>
                        <li>
                            Суд процессын алып бара торган хокук саклау, дәүләт, хакимият органнары бездән шәхси мәгълүмат таләп итәләр.
                        </li>
                        <li>
                            Сезгә теге яки бу товар яки хезмәт тәкъдим итәргә тели торган өченче затларга бирелергә мөмкин. Ләкин бары тик
                            Сезнең рөхсәт белән.
                        </li>
                        <li>
                            &laquo;Хозур&raquo; Нәшрият Йорты компаниясенең сайты Интернет провайдер, сайт хостерлары тарафыннан, үз
                            кулланучылары турында мәгълүмат туплау өчен, кулланыла ала.
                        </li>
                    </ul>
                    <p>
                        Әгәр Сезнең шәхси мәгълүматны фаш итүне юридик яктан мөһим дип тапсак, Сезнең шәхси мәгълүматны өченче затларга,
                        оешмаларга яки аерым бер затларга бирә алабыз.
                    </p>
                    <p>
                        <strong>Конфиденциаль мәгълүматны саклауны тәэмин итү.</strong>
                    </p>
                    <p>
                        Без, Сезнең шәхси мәгълүматларыгызны саклау өчен, барлык мөмкин булган чаралар күрәбез һәм сезнең шәхси
                        мәгълүматларны анализлый торган яки эшкәртә торган өченче затлардан да шуны таләп итәбез. Сезнең шәхси
                        мәгълүматларыгызга, рөхсәт ителмәгән керү, үзгәрешләр кертү яки хокуксыз файдалануны булдырмау өчен, бары тик безнең
                        хезмәткәрләргә һәм агентларга гына рөхсәт ителә.
                    </p>
                    <p>
                        <strong>Конфиденциальлек сәясәтендәге үзгәрешләр</strong>
                    </p>
                    <p>
                        &laquo;Хозур&raquo; Нәшрият Йорты компаниясенең конфиденциальлек сәясәтенә <strong>31.12.2014 </strong>көнне соңгы
                        тапкыр үзгәрешләр&nbsp;кертелде.
                    </p>
                    <p>
                        Кирәк булган саен, без &laquo;Хозур&raquo; Нәшрият Йорты компаниясе сайтларында, аның яңартылган версиясен урнаштыру
                        юлы белән, конфиденциальлек сәясәтен үзгәртә алабыз. Шәхси мәгълүматны ничек куллануыбыз турында белеп тору өчен,
                        Сезне үзебезнең ресурсларга мөмкин кадәр ешрак керергә чакырабыз.
                    </p>
                    <p>
                        Әлеге Сәясәткә өстәмә буларак &laquo;Хозур&raquo; Нәшрият Йорты компаниясе сайтларында аерым реклама кампанияләре
                        һәм акцияләр булырга мөмкин. Алар өстәмә конфиденциальлек сәясәте яки конфиденциальлек шартлары белән җайга
                        салыначак. Без Сезгә бу өстәмә шартлар яки сәясәт белән танышырга тәкъдим итәбез, чөнки сез аларны үтәргә тиеш
                        булачаксыз. Теләсә нинди өстәмә конфиденциальлек сәясәте яки шартлары ачык кулланылышта булачак.
                    </p>

                    <Box textAlign="right">
                        <p>
                            <em>Ихтирам белән,</em>
                        </p>
                        <p>
                            <em> &laquo;Хозур&raquo; Нәшрият Йорты&nbsp;</em>
                            <a href="http://huzur.ru/">
                                <span>
                                    <em>http://huzur.ru/</em>
                                </span>
                            </a>
                        </p>
                        <p>
                            <em>Онлайн мәдрәсәнең хосусый милек иясе </em>
                            <a href="http://medrese.tatar">
                                <span>
                                    <em>http://medrese.tatar</em>
                                </span>
                            </a>
                        </p>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        ...theme.typography.body1,
        '& a': {
            color: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.primary.dark,
                textDecoration: 'none',
            },
        },
    },
}));

export default PrivacyPolicy;
