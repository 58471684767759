import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
    accordionMobile: {
        width: '100%',
        borderRadius: '0 !important',
        boxShadow: 'none',
        '&.Mui-expanded': {
            margin: 0,
            '&:before': {
                opacity: 1,
            },
            '& + .MuiAccordion-root:before': {
                display: 'block',
            },
        },
    },
    accordion: {
        width: '100%',
        marginBottom: theme.spacing(2),
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '8px !important',
        boxShadow: 'none',
        transition: 'border-color .3s ease, box-shadow .3s ease',
        '&.Mui-expanded': {
            borderColor: 'transparent',
            boxShadow: '0px 3px 9px rgba(0, 0, 0, 0.08), 0px 1px 22px rgba(0, 0, 0, 0.04), 0px 6px 14px rgba(0, 0, 0, 0.05)',
        },
    },
    accordionSummary: {
        padding: '0',
        '& .MuiAccordionSummary-content': {
            order: 1,
        },
    },
    expandIconDense: {
        margin: 12,
        [theme.breakpoints.down('md')]: {
            margin: '8px 0',
        },
    },
    headTitle: {
        lineHeight: '20px',
    },
    doneMarkText: {
        color: theme.palette.surface.main,
        padding: '9px 12px 7px',
        marginLeft: 16,
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: 8,
    },
    doneMarkIcon: {
        color: theme.palette.surface.main,
        marginLeft: 16,
    },
    doneLink: {
        color: theme.palette.secondary.light,
    },
    currentLink: {
        color: theme.palette.primary.main,
    },
    disableLink: {
        color: 'rgba(0, 0, 0, 0.38)',
    },
    visible: {
        visibility: 'visible',
    },
    hidden: {
        visibility: 'hidden',
    },
}));
