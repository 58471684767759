import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    Grid,
    Typography,
    Box,
    Radio,
    FormControlLabel,
    RadioGroup,
    useTheme,
    useMediaQuery,
    Checkbox,
    FormGroup,
} from '@material-ui/core';
import { ITestQuestion, ITestAnswer } from '../../types/LessonTestTypes';
import { useStyles } from './style';
import { useStores } from '../../hooks/useStores';

interface ITestQuestionProps {
    question: ITestQuestion;
    index: number;
}

const TestQuestion: React.FC<ITestQuestionProps> = observer(({ question, index }) => {
    const { LessonTestStore: store } = useStores();
    const classes = useStyles();

    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));

    // Renders
    const renderAnswers = () => {
        if (question.type === 'radio') {
            return (
                <RadioGroup name={`${question.id}`} value={store.values[question.id]} onChange={store.handleChange}>
                    {question.answers.map(({ id, caption }: ITestAnswer) => (
                        <FormControlLabel
                            key={id}
                            value={`${id}`}
                            control={<Radio required />}
                            label={caption}
                            className={classes.radioLabel}
                        />
                    ))}
                </RadioGroup>
            );
        } else {
            return (
                <FormGroup>
                    {question.answers.map(({ id, caption }: ITestAnswer) => (
                        <FormControlLabel
                            key={id}
                            control={
                                <Checkbox
                                    name={`${question.id}`}
                                    value={`${id}`}
                                    onChange={store.handleChange}
                                    checked={Array.isArray(store.values[question.id]) ? store.values[question.id].includes(`${id}`) : false}
                                />
                            }
                            label={caption}
                            className={classes.radioLabel}
                        />
                    ))}
                </FormGroup>
            );
        }
    };

    return (
        <Grid container spacing={isXS ? 2 : 3}>
            <Grid item>
                <div className={classes.number}>
                    <Typography variant={isXS ? 'subtitle1' : 'h6'}>{index}</Typography>
                </div>
            </Grid>
            <Grid item xs>
                <Box mb={{ xxs: 1, md: 2 }}>
                    <Typography variant="h6">{question.caption}</Typography>
                </Box>
                {renderAnswers()}
            </Grid>
        </Grid>
    );
});

export default TestQuestion;
