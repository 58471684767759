import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Container, Box, Typography, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { ISuccessCardProps } from '../../types/AuthTypes';
import { useStyles } from './style';
import logo from '../../img/logo.png';

const SuccessCard: React.FC<ISuccessCardProps> = ({ email, children }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Container maxWidth="xl">
            <Box className={classes.authRow}>
                <Box className={classes.card}>
                    <img src={logo} alt="" className={classes.logo} />

                    <Box mb={3}>
                        <Typography variant="h6">{t("SuccessCardCheckEmail")}</Typography>
                    </Box>
                    <Box mb={3} textAlign="center">
                        <Typography color="textSecondary">{children}</Typography>
                    </Box>

                    <Box width="100%" display="flex" flexDirection="column" textAlign="center" mb={3}>
                        <Typography variant="caption" color="textSecondary">
                        {t("SuccessCardEmail")}:
                        </Typography>
                        <Typography>{email}</Typography>
                    </Box>

                    <Link to="/" className={clsx(classes.link, classes.fullWidth)}>
                        <Button fullWidth color="primary" variant="contained" startIcon={<ArrowBackIcon />}>
                        {t("SuccessCardGoBack")} //
                        </Button>
                    </Link>
                </Box>
            </Box>
        </Container>
    );
};

export default SuccessCard;
