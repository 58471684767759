import React from 'react';
import { Typography, FormLabel, makeStyles } from '@material-ui/core';

const FormLabelCustom: React.FC<any> = props => {
    const classes = useStyles();

    return (
        <FormLabel
            {...props}
            component={({ children }: { children: any }) => (
                <Typography variant="body2" className={classes.formLabel}>
                    {children[0]}
                    {props.required && <sup className={classes.required}> *</sup>}
                </Typography>
            )}
        >
            {props.children}
        </FormLabel>
    );
};

export const useStyles = makeStyles(theme => ({
    formLabel: {
        color: theme.palette.surface.main,
        marginBottom: 2,
    },
    required: {
        color: 'red',
        transform: 'translate(4px, 1px)',
    },
}));

export default FormLabelCustom;
