import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { useStores } from './hooks/useStores';

// Screens
import SignIn from './screens/Auth/SignInScreen';
import SignInEmail from './screens/Auth/SignInEmailScreen';
import ResetPassword from './screens/Auth/ResetPasswordScreen';
import ResetPasswordForm from './screens/Auth/ResetPasswordFormScreen';
import SignUpEmail from './screens/Auth/SignUpEmailScreen';
import SignUp from './screens/Auth/SignUpScreen';
import NotConfirm from './screens/Auth/NotConfirmScreen';
import Confirmed from './screens/Auth/ConfirmedScreen';

import Main from './screens/MainScreen';
import Subjects from './screens/SubjectsScreen';
// import News from './screens/NewsScreen';
// import NewsView from './screens/NewsViewScreen';
import About from './screens/AboutScreen';
import Profile from './screens/ProfileScreen';
import Subject from './screens/SubjectScreen';
import Lesson from './screens/LessonScreen';
import LessonTest from './screens/LessonTestScreen';
import LessonTestResult from './screens/LessonTestResultScreen';
import NotFound from './screens/NotFoundScreen';
import PrivacyPolicy from './screens/PrivacyPolicy';

// Components
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Loader from './components/UI/Loader';
import MainLoader from './components/MainLoader';

import { useStyles } from './components/Auth/style';

const App: React.FC = observer(() => {
    const { AuthStore: store, ProfileStore } = useStores();
    const AuthClasses = useStyles();
    const location = useLocation();

    //// Effects
    useEffect(() => {
        store.checkAuth();
    }, [store]);

    useEffect(() => {
        return () => {
            ProfileStore.resetStore();
        };
    }, [ProfileStore]);

    useEffect(() => {
        if (!ProfileStore.userGetted && store.isAuth) {
            ProfileStore.getProfile();
            // for request count optimize
            ProfileStore.setUserGetted(true);
        }

        if (!store.isAuth) {
            // for new get profile request after logout
            ProfileStore.setUserGetted(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.isAuth]);

    //// Renders
    // Main loader
    if (!store.completeCheckAuth || ProfileStore.serverError) {
        return <Loader />;
    }

    const isMainPage = () => {
        if (store.isAuth) {
            return false;
        }

        return location.pathname === '/';
    };

    const isFullPageScreen = () => {
        switch (location.pathname) {
            case '/login':
            case '/login/email':
            case '/reset-password':
            case '/reset-password/new':
            case '/register':
            case '/register/email':
            case '/register/confirmed':
            case '/not-found':
                return true;

            case '/profile':
                return !ProfileStore.confirmed;

            default:
                return false;
        }
    };

    // Route Stacks
    const renderIsAuthStack = () => {
        if (ProfileStore.loadingProfile) {
            return <MainLoader />;
        }

        return (
            <>
                <Header isMainPage={isMainPage()} />

                <Box className={isFullPageScreen() ? AuthClasses.authPage : ''} flex={1}>
                    <Box display="flex" flex={1}>
                        <Switch>
                            <Route exact path="/subjects" component={Subjects} />
                            <Route exact path="/subjects/:subjectId" component={Subject} />
                            <Route exact path="/subjects/:subjectId/lesson/:lessonId" component={Lesson} />
                            <Route exact path="/subjects/:subjectId/lesson/:lessonId/tests/:testId" component={LessonTest} />
                            <Route path="/subjects/:subjectId/lesson/:lessonId/tests/:testId/result" component={LessonTestResult} />
                            {/* <Route exact path="/news" component={News} /> */}
                            {/* <Route path="/news/:newsId" component={NewsView} /> */}
                            <Route path="/about" component={About} />
                            <Route path="/profile" component={ProfileStore.confirmed ? Profile : NotConfirm} />
                            <Route path="/policy" component={PrivacyPolicy} />
                            <Route path="/not-found" component={NotFound} />

                            <Route path="/register/confirmed" component={Confirmed} />

                            <Route exact path="/">
                                <Redirect to="/subjects" />
                            </Route>

                            <Route path="*">
                                <Redirect to="/not-found" />
                            </Route>
                        </Switch>
                    </Box>
                </Box>

                {!isFullPageScreen() && !isMainPage() && <Footer />}

                <ReactNotification />
            </>
        );
    };
    
    const renderIsNotAuthStack = () => {
        return (
            <>
                <Header isMainPage={isMainPage()} />

                <Box className={isFullPageScreen() ? AuthClasses.authPage : ''} flex={1}>
                    <Box display="flex" flex={1}>
                        <Switch>
                            <Route exact path="/" component={Main} />
                            <Route exact path="/login" component={SignIn} />
                            <Route path="/login/email" component={SignInEmail} />
                            <Route exact path="/reset-password" component={ResetPassword} />
                            <Route path="/reset-password/new" component={ResetPasswordForm} />
                            <Route exact path="/register" component={SignUp} />
                            <Route path="/register/email" component={SignUpEmail} />

                            <Route exact path="/subjects" component={Subjects} />
                            <Route path="/subjects/:subjectId" component={Subject} />
                            {/* <Route exact path="/news" component={News} /> */}
                            {/* <Route path="/news/:newsId" component={NewsView} /> */}
                            <Route path="/about" component={About} />

                            <Route path="/policy" component={PrivacyPolicy} />
                            <Route path="/not-found" component={NotFound} />

                            <Route path="*">
                                <Redirect to="/" />
                            </Route>
                        </Switch>
                    </Box>
                </Box>

                {!isFullPageScreen() && !isMainPage() && <Footer />}

                <ReactNotification />
            </>
        );
    };

    return store.isAuth ? renderIsAuthStack() : renderIsNotAuthStack();
});

export default App;
