import React from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import Loader from './UI/Loader';

const MainLoader: React.FC = () => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Loader />
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        zIndex: 5,
    },
}));

export default MainLoader;
