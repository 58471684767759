import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
    number: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        height: 40,
        marginTop: theme.spacing(0.7),
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 8,
        '& .MuiTypography-root': {
            marginTop: '2px',
        },
        [theme.breakpoints.down('xs')]: {
            width: 32,
            height: 32,
        },
    },
    radioLabel: {
        alignItems: 'flex-start',
        marginBottom: theme.spacing(0.6),
        '& .MuiFormControlLabel-label': {
            marginTop: 9,
        },
    },
}));
