import React from 'react';
import { ISubjectProgram } from '../../types/SubjectTypes';
import SubjectProgramItem from './SubjectProgramItem';

interface ISubjectProgramProps {
    programs: ISubjectProgram[];
    showDoneText?: boolean;
    dense?: boolean;
}

const SubjectProgram: React.FC<ISubjectProgramProps> = ({ programs = [], showDoneText = false, dense = false }) => {
    return (
        <>
            {programs.map((program: ISubjectProgram, index: number) => (
                <SubjectProgramItem key={program.id} program={program} index={index} showDoneText={showDoneText} dense={dense} />
            ))}
        </>
    );
};

export default SubjectProgram;
