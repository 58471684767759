import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

interface MenuLinkProps extends NavLinkProps {
    theme?: 'white' | 'dark';
}

const MenuLink: React.FC<MenuLinkProps> = props => {
    const classes = useStyles();

    return (
        <NavLink
            activeClassName="selected"
            className={clsx(classes.menuLink, props.theme === 'dark' ? classes.menuLinkDark : classes.menuLinkWhite)}
            {...props}
        >
            {props.children}
        </NavLink>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    menuLink: {
        textDecoration: 'none',
        fontWeight: 900,
        fontSize: 18,
        letterSpacing: '2.2px',
        textTransform: 'uppercase',
        transition: 'color .3s ease',
        [theme.breakpoints.down('lg')]: {
            fontSize: 16,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
    },
    menuLinkWhite: {
        color: theme.palette.surface.main,
        '&:hover': {
            color: '#000',
        },
        '&.selected': {
            color: theme.palette.primary.main,
        },
    },
    menuLinkDark: {
        color: '#e4e4e4',
        '&:hover': {
            color: '#fff',
        },
        '&.selected': {
            color: theme.palette.primary.main,
        },
    },
}));

export default MenuLink;
