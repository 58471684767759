// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { action, observable, runInAction } from 'mobx';
import { api } from '../api';
import { ILessonData } from '../types/LessonTypes';
import { transformDetailLessonData } from '../utils/dataTransformers';

export class LessonStore {
    @observable loading = false;

    @observable isLessonStarted = false;
    @observable isLessonViewed = false;

    @observable lesson: ILessonData | null = null;

    @action
    getLesson = (lessonId: number) => {
        this.loading = true;

        api.get(`/topics/${lessonId}`)
            .then(response => {
                if (response.data.success) {
                    runInAction(() => {
                        this.lesson = transformDetailLessonData(response.data.data);
                    });
                }
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    };

    @action
    startLesson = (userId: number, lessonId: number, onStarted?: () => void) => {
        this.isLessonStarted = true;
        const data = {
            user_id: userId,
            topic_id: lessonId,
        };

        api.post(`users/start_topic`, data).then(response => {
            if (response.data.success) {
                const lesson = JSON.parse(JSON.stringify(this.lesson));

                runInAction(() => {
                    this.lesson = { ...lesson, started: true };
                });
                if (onStarted) {
                    onStarted();
                }
            }
        });
    };

    @action
    viewLesson = (userId: number, lessonId: number, onViewed: () => void) => {
        this.isLessonViewed = true;
        const data = {
            user_id: userId,
            topic_id: lessonId,
        };

        api.post(`users/topic_viewed`, data).then(response => {
            if (response.data.success) {
                const lesson = JSON.parse(JSON.stringify(this.lesson));

                runInAction(() => {
                    this.lesson = { ...lesson, viewed: true };
                });

                onViewed();
            }
        });
    };

    @action
    completeLesson = () => {
        const lesson = JSON.parse(JSON.stringify(this.lesson));

        runInAction(() => {
            this.lesson = { ...lesson, completed: true };
        });
    };

    @action
    setRating = (userId: number, lessonId: number, rating: number) => {
        const data = {
            user_id: userId,
            topic_id: lessonId,
            topicScore: rating,
        };

        api.post(`users/set_topic_rating`, data).then(response => {
            if (response.data.success) {
                const lesson = JSON.parse(JSON.stringify(this.lesson));

                runInAction(() => {
                    this.lesson = { ...lesson, youScore: rating };
                });
            }
        });
    };

    @action
    resetStore = () => {
        this.loading = false;
        this.isLessonStarted = false;
        this.isLessonViewed = false;
        this.lesson = null;
    };
}
