import React from 'react';
import { AuthStore, ProfileStore, SubjectStore, LessonStore, LessonTestStore, NewsStore, AboutStore } from '../stores';

export const stores = {
    AuthStore: new AuthStore(),
    ProfileStore: new ProfileStore(),
    SubjectStore: new SubjectStore(),
    LessonStore: new LessonStore(),
    LessonTestStore: new LessonTestStore(),
    NewsStore: new NewsStore(),
    AboutStore: new AboutStore(),
};

export const storesContext = React.createContext(stores);
