import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Container, Box, Typography, makeStyles, Theme } from '@material-ui/core';
import CourseProgramSection from '../components/About/CourseProgramSection';
import AboutAdvantagesSection from '../components/About/AboutAdvantagesSection';
import TeachersCarousel from '../components/TeachersCarousel';

import bg from '../img/about_page_bg.jpg';
import CourseAdvantagesSection from '../components/About/CourseAdvantagesSection';
import TrainingStepsSection from '../components/About/TrainingStepsSection';
import { useStores } from '../hooks/useStores';
import { useTranslation } from 'react-i18next';

const About: React.FC = observer(() => {
    const { AboutStore: store } = useStores();
    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(() => {
        store.getAllTeachers();
    }, [store]);

    return (
        <Box width="100%" display="flex" flexDirection="column" justifyItems="center">
            <div className={classes.root}>
                <div className={classes.bg}></div>
                <Container maxWidth="xl">
                    <div className={classes.mainBlock}>
                        <Box mb={{ xxs: 0, sm: 2 }}>
                            {/* <Typography variant="h5">Татар мәдрәсәсе</Typography> */}
                            <Typography className={classes.title}>{t("TatarMedrese")}</Typography>
                        </Box>

                        <Box mb={4}>
                            <Typography
                                color="textSecondary"
                                component="div"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        '<p>' + t("AboutScreenText1") + '</p>' +
                                        '<p>' + t("AboutScreenText2") + '</p>',
                                }}
                            />
                        </Box>
                    </div>
                </Container>
            </div>

            <Container maxWidth="xl">
                <Box mb={{ xxs: 14, lg: 9 }}>
                    <CourseProgramSection />
                </Box>
                <Box mb={{ xxs: 16, md: 17, xl: 22 }}>
                    <AboutAdvantagesSection />
                </Box>
                <Box mb={{ xxs: 8, md: 10, xl: 14 }}>
                    <TeachersCarousel teachers={store.teachers} />
                </Box>
            </Container>

            <div className={classes.courseAdvantagesSection}>
                <Container maxWidth="xl">
                    <CourseAdvantagesSection />
                </Container>
            </div>

            <Container maxWidth="xl">
                <Box mb={14}>
                    <TrainingStepsSection />
                </Box>
            </Container>
        </Box>
    );
});

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(8),
        [theme.breakpoints.down('lg')]: {
            marginBottom: theme.spacing(4),
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(0),
        },
    },
    bg: {
        position: 'absolute',
        bottom: 0,
        left: '50%',
        width: '100%',
        height: '95%',
        transform: 'translateX(-50%)',
        background: `url(${bg}) no-repeat top right`,
        backgroundSize: 'auto 100%',
        zIndex: -1,
        '@media (max-width: 1439px)': {
            width: 1440,
        },
        '@media (max-width: 1169px)': {
            width: 1360,
            height: 620,
            backgroundSize: 'auto 90%',
        },
        [theme.breakpoints.down('lg')]: {
            width: 1360,
            height: 620,
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    mainBlock: {
        maxWidth: 545,
        minHeight: 680,
        height: '90vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(10, 0),
        [theme.breakpoints.down('xl')]: {
            minHeight: 620,
        },
        [theme.breakpoints.down('lg')]: {
            minHeight: 670,
            height: 'auto',
        },
        [theme.breakpoints.down('md')]: {
            minHeight: 600,
            height: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: 'auto',
            height: 'auto',
            padding: theme.spacing(15, 0, 12),
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(11, 0, 7),
        },
    },
    title: {
        fontSize: 60,
        lineHeight: '72px',
        fontWeight: 400,
        [theme.breakpoints.down('lg')]: {
            fontSize: 50,
            lineHeight: '62px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 38,
            lineHeight: '56px',
        },
    },

    courseAdvantagesSection: {
        padding: theme.spacing(9.5, 0),
        marginBottom: theme.spacing(11),
        backgroundColor: '#FFF6F3',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(8, 0),
            marginBottom: theme.spacing(8),
        },
    },
}));

export default About;
