import React from 'react';
import { Paper, Box, Typography, makeStyles, useTheme, useMediaQuery, Theme } from '@material-ui/core';
import Loader from '../UI/Loader';

interface IProfileLearnProgressNumber {
    value: number;
    loading: boolean;
    children: string;
}

const ProfileLearnProgressNumber: React.FC<IProfileLearnProgressNumber> = ({ value, loading, children }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isLG = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <Paper elevation={0} variant="outlined">
            <Box display="flex" alignItems="center" flexWrap="nowrap" p={{ xxs: 1, sm: 2, xl: 3 }}>
                <Box mr={3}>
                    <Paper elevation={0} className={classes.numberPaper}>
                        {loading && <Loader />}
                        {!loading && (
                            <Typography variant={isLG ? 'h5' : 'h4'} className={classes.mediumEmphasisColor}>
                                {value}
                            </Typography>
                        )}
                    </Paper>
                </Box>
                <Typography variant={isLG ? 'subtitle1' : 'h6'}>{children}</Typography>
            </Box>
        </Paper>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    numberPaper: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 80,
        height: 80,
        background: '#FFD9CD',
        [theme.breakpoints.down('lg')]: {
            width: 70,
            height: 70,
        },
        [theme.breakpoints.down('xs')]: {
            width: 60,
            height: 60,
        },
    },
    mediumEmphasisColor: {
        color: theme.palette.surface.main,
    },
}));

export default ProfileLearnProgressNumber;
