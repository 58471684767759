import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { configure } from 'mobx';
import { CssBaseline, ThemeProvider, createTheme } from '@material-ui/core';

import './utils/momentLocalteTat';
import App from './App';
import './index.scss';
import * as Sentry from '@sentry/browser';
import "./i18n/i18n";

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production') {
    Sentry.init({ dsn: 'https://91b99f72161a45f281d494644829ea76@sentry.netimob.com/46' });
}

configure({ enforceActions: 'observed' });

const theme = createTheme({
    breakpoints: {
        values: {
            //@ts-ignore
            xxs: 0,
            xs: 375,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1168,
        },
    },
    palette: {
        primary: { main: '#FE8157' },
        secondary: { main: '#009688' },
        surface: {
            light: 'rgba(0,0,0, .38)',
            main: 'rgba(0,0,0, .6)',
            dark: 'rgba(0,0,0, .87)',
        },
    },
    typography: {
        fontFamily: ['MuseoSansCyrl', 'sans-serif'].join(','),
    },
    overrides: {
        MuiPaper: {
            rounded: {
                borderRadius: 16,
                '@media (max-width: 575px)': {
                    borderRadius: 8,
                },
            },
        },
        MuiButton: {
            root: {
                fontWeight: 700,
                padding: '9px 16px 7px',
                textTransform: 'none',
                borderRadius: 8,
            },
            containedPrimary: {
                color: '#fff',
                boxShadow: '0px 1px 9px rgba(0, 0, 0, 0.08), 0px 3px 8px rgba(0, 0, 0, 0.04), 0px 2px 8px rgba(0, 0, 0, 0.05)',
                '&:hover': {
                    backgroundColor: 'rgba(240, 106, 65, 1)',
                    boxShadow: '0px 1px 9px rgba(0, 0, 0, 0.08), 0px 3px 8px rgba(0, 0, 0, 0.04), 0px 2px 8px rgba(0, 0, 0, 0.05)',
                },
            },
            outlined: {
                padding: '9px 15px 7px',
            },
        },
        MuiOutlinedInput: {
            root: {
                '&:hover:not(.Mui-focused)': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.4)',
                    },
                },
            },
            notchedOutline: {
                borderColor: 'rgba(0, 0, 0, 0.12)',
                transition: 'border-color .3s ease',
            },
        },
        MuiAccordionSummary: {
            root: {
                padding: '0',
                '& .MuiAccordionSummary-content': {
                    order: 1,
                },
            },
            expandIcon: {
                margin: '16px 12px',
                '@media (max-width: 575px)': {
                    margin: '8px 0',
                },
            },
        },
        MuiAvatar: {
            colorDefault: {
                backgroundColor: '#FFC0AB',
            },
        },
    },
});
const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
            <App />
        </Router>
    </ThemeProvider>
);

// if (module.hot) {
//     module.hot.accept();
// }
