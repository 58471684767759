import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { Container, Box, Typography, TextField } from '@material-ui/core';

import FormLabelCustom from '../../components/UI/FormLabelCustom';
import SuccessCard from '../../components/Auth/SuccessCard';
import LoaderButton from '../../components/UI/LoaderButton';
import { useStores } from '../../hooks/useStores';

import logo from '../../img/logo.png';
import { useStyles } from '../../components/Auth/style';
import { useTranslation } from 'react-i18next';

const ResetPassword: React.FC = observer(() => {
    const classes = useStyles();
    const { AuthStore: store } = useStores();
    const [email, setEmail] = useState('');
    const location = useLocation();
    const { t } = useTranslation();

    //// Effects
    useEffect(() => {
        // @ts-ignore
        setEmail(location.state?.email);

        return () => {
            store.clearConfirmEmailMessage();
            store.clearErrors();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store]);

    //// Handlers
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handleReset = () => {
        store.resetPassword(email);
    };

    //// Renders
    const renderResetForm = () => {
        return (
            <Container maxWidth="xl">
                <Box className={classes.authRow}>
                    <Box className={classes.card}>
                        <img src={logo} alt="" className={classes.logo} />

                        <Box mb={3}>
                            <Typography variant="h6">{t("PasswordForgot")}</Typography>
                        </Box>
                        <Box mb={3} textAlign="center">
                            <Typography color="textSecondary">
                                {t("PasswordResetDescription")}
                            </Typography>
                        </Box>

                        <Box width="100%" display="flex" flexDirection="column" mb={1}>
                            <Box mb={3}>
                                <FormLabelCustom required>Email</FormLabelCustom>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name="email"
                                    value={email}
                                    placeholder={t("PasswordPlaceholder")}
                                    onChange={handleChange}
                                    error={!!store.errorMessages?.email}
                                    helperText={store.errorMessages?.email}
                                    disabled={store.loading}
                                />
                            </Box>
                        </Box>

                        <LoaderButton fullWidth color="primary" variant="contained" onClick={handleReset} loading={store.loading}>
                            {t("PasswordReset")}
                        </LoaderButton>
                    </Box>
                </Box>
            </Container>
        );
    };

    return store.confirmEmailMessage ? (
        <SuccessCard email={email}>{t("PasswordSentToEmail")}</SuccessCard>
    ) : (
        renderResetForm()
    );
});

export default ResetPassword;
