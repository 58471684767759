export const transformTeacherData = (data: any) => {
    const description: string[] = [];

    for (const key in data.position) {
        if (Object.prototype.hasOwnProperty.call(data.position, key)) {
            key !== 'id' && description.push(data.position[key]);
        }
    }

    return {
        id: data?.id,
        avatar: data?.photo,
        name: `${data?.surname || ''} ${data?.name || ''} ${data?.middleName || ''}`,
        description,
    };
};

const transformLessonData = (data: any) => {
    return {
        id: data?.id,
        title: data?.caption || '',
        started: Boolean(parseInt(data?.started)),
        viewed: Boolean(parseInt(data?.viewed)),
        status: 'notAvailable',
        completed: Boolean(parseInt(data?.completed)),
    };
};

export const transformProgramData = (data: any) => {
    return {
        id: data?.id,
        title: data?.caption || '',
        completed: Boolean(parseInt(data?.completed)),
        status: 'notAvailable',
        lessons: data?.topics?.map((lesson: any) => transformLessonData(lesson)) || [],
    };
};

export const transformSubjectData = (data: any) => {
    return {
        id: data?.id,
        title: data?.caption,
        img: data?.previewPicture,
        shortDescription: data?.courseDescription,
        description: data?.programDescription,
        progress: {
            current: parseInt(data?.courseSubCount?.completed_topic_cnt) || 0,
            all: parseInt(data?.courseSubCount?.topics_cnt) || 0,
        },
        teachers: data?.teachers?.map((teacher: any) => transformTeacherData(teacher)) || [],
        programs: data?.lessons?.map((program: any) => transformProgramData(program)) || [],
    };
};

export const transformMySubjectData = (data: any) => {
    return {
        id: data?.courseInfo?.id,
        title: data?.courseInfo?.caption,
        img: data?.courseInfo?.previewPicture,
        progress: {
            current: parseInt(data?.completed_topic_cnt) || 0,
            all: parseInt(data?.topics_cnt) || 0,
        },
        teachers: data?.courseInfo?.teachers?.map((teacher: any) => transformTeacherData(teacher)) || [],
    };
};

// Detail pages data transformers
export const transformDetailLessonData = (data: any) => {
    return {
        id: data?.id,
        caption: data?.caption,
        description: data?.description,
        video: data?.video,
        audio: data?.audio,
        audioTranscription: data?.audioTranscription,
        additionalInfo: data?.additionalInfo,
        programId: parseInt(data?.lesson_id),
        testId: data?.test?.id,
        subjectId: parseInt(data?.lesson?.course_id),
        started: Boolean(parseInt(data?.started)),
        viewed: Boolean(parseInt(data?.viewed)),
        completed: Boolean(parseInt(data?.completed)),
        youScore: data?.youScore,
    };
};
