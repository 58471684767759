// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { action, observable, runInAction } from 'mobx';
import { api } from '../api';
import { ISubjectTeacher } from '../types/SubjectTypes';
import { transformTeacherData } from '../utils/dataTransformers';

export class AboutStore {
    @observable loading = false;

    @observable teachers: ISubjectTeacher[] = [];

    @action
    getAllTeachers = () => {
        this.loading = true;

        api.get(`/users/teachers`)
            .then(response => {
                if (response.data.success) {
                    runInAction(() => {
                        this.teachers = response.data.data.map((teacher: any) => transformTeacherData(teacher));
                    });
                }
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    };
}
