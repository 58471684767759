import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Paper, Box, Typography, useTheme, useMediaQuery } from '@material-ui/core';

import { IProfilePasswordFormState } from '../../types/ProfileTypes';
import TextButton from '../UI/TextButton';
import FormLabelCustom from '../UI/FormLabelCustom';
import PasswordInput from '../UI/PasswordInput';
import { useStores } from '../../hooks/useStores';
import LoaderButton from '../UI/LoaderButton';
import { useStyles } from './style';
import { useTranslation } from 'react-i18next';

const ProfilePasswordForm: React.FC = observer(() => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { ProfileStore: store } = useStores();
    const theme = useTheme();
    const isLG = useMediaQuery(theme.breakpoints.down('lg'));

    const [values, setValues] = useState<IProfilePasswordFormState>({
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    });

    const loading = store.updateLoading.password;

    //// Effects
    useEffect(() => {
        return () => {
            store.setIsEdit('password', false);
        };
    }, [store]);

    useEffect(() => {
        // Reset password field after close editing
        if (!store.isEdit.password) {
            setValues({
                oldPassword: '',
                newPassword: '',
                confirmNewPassword: '',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.isEdit.password]);

    //// Handlers
    const handleChange = (event: any) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const handleCancel = () => {
        store.setIsEdit('password', false);
    };

    const handleSave = () => {
        if (store.profile?.id) {
            store.changePassword(store.profile?.id, {
                old_password: values.oldPassword,
                password: values.newPassword,
                password_confirmation: values.confirmNewPassword,
            });
        }
    };

    //Renders
    const renderForm = () => {
        return (
            <>
                <Box mb={{ xxs: 2, md: 3 }}>
                    <FormLabelCustom required>{t("PasswordOld")}</FormLabelCustom>
                    <PasswordInput
                        name="oldPassword"
                        value={values.oldPassword}
                        onChange={handleChange}
                        error={!!store.errorMessages?.old_password}
                        helperText={store.errorMessages?.old_password}
                        disabled={loading}
                    />
                </Box>
                <Box mb={{ xxs: 2, md: 3 }}>
                    <FormLabelCustom required>{t("PasswordNew")}</FormLabelCustom>
                    <PasswordInput
                        name="newPassword"
                        value={values.newPassword}
                        onChange={handleChange}
                        error={!!store.errorMessages?.password}
                        helperText={store.errorMessages?.password}
                        disabled={loading}
                    />
                </Box>
                <Box mb={{ xxs: 3, lg: 5 }}>
                    <FormLabelCustom required>{t("PasswordNewRepeat")}</FormLabelCustom>
                    <PasswordInput
                        name="confirmNewPassword"
                        value={values.confirmNewPassword}
                        onChange={handleChange}
                        error={!!store.errorMessages?.password_confirmation}
                        helperText={store.errorMessages?.password_confirmation}
                        disabled={loading}
                    />
                </Box>
                <LoaderButton fullWidth color="primary" variant="contained" onClick={handleSave} loading={loading}>
                    {t("Save")}
                </LoaderButton>
            </>
        );
    };

    return (
        <Paper elevation={0} variant="outlined">
            <Box p={{ xxs: 2, sm: 3 }}>
                <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={store.isEdit.password ? 3 : 0}>
                    <Typography variant={isLG ? 'subtitle1' : 'h6'} className={classes.mediumEmphasisColor}>
                        {t("Password")}
                    </Typography>
                    {store.isEdit.password ? (
                        <TextButton onClick={handleCancel}>{t("Cancel")}</TextButton>
                    ) : (
                        <TextButton onClick={() => store.setIsEdit('password', true)}>{t("Cancel")}</TextButton>
                    )}
                </Box>

                {store.isEdit.password && renderForm()}
            </Box>
        </Paper>
    );
});

export default ProfilePasswordForm;
