import axios from 'axios';
import { IRequestResponse } from '../types/CommonTypes';
import { showNotification } from '../utils/showNotification';
import { _tokenStorageKey } from '../stores/AuthStore';
import i18n from '../i18n/i18n';

export const BASE_URL = process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/v1` : 'https://api.medrese.tatar/api/v1';
export const BASE_URL_RU = process.env.REACT_APP_API_RU_URL ? `${process.env.REACT_APP_API_RU_URL}/v1` : 'https://api-ru.medrese.tatar/api/v1';

axios.interceptors.request.use(
    async config => {
        // console.log('Request', { url: config.url, ...config.headers });
        return {
            ...config,
            baseURL: i18n.language === "ru" ? BASE_URL_RU : BASE_URL,
            headers: {
                'Content-Type': 'application/json',
                'App-Platform': 'web',
                ...config.headers,
            },
        };
    },
    error => {
        return Promise.reject(error);
    },
);

axios.interceptors.response.use(
    async response => {
        // console.log('Response', response);
        return response;
    },
    async error => {
        // console.log('ResponseError', error.message);
        // console.log('ResponseErrorData', error.response?.data);

        if (error.response?.data) {
            showNotification('danger', error.response.data?.message || 'Неизвестная ошибка');
        }

        // let response = error.config;
        if (error.message === 'Network Error') {
            console.log('Проверьте соединение');

            return Promise.reject(error);
        }
        if (error.response?.status) {
            switch (error.response?.status) {
                case 401: {
                    if (axios.defaults.headers['Authorization']) {
                        api.clearToken();
                        localStorage.removeItem(_tokenStorageKey);
                        document.location.replace('/');
                    }
                    throw error;
                }
                case 400: {
                    throw error;
                }
                default: {
                    console.log('Ошибка на сервере');
                    throw error;
                }
            }
        }
    },
);

class API {
    get(apiUrl: string) {
        return axios.get<IRequestResponse>(apiUrl);
    }
    post(apiUrl: string, data: any) {
        return axios.post<IRequestResponse>(apiUrl, data);
    }
    put(apiUrl: string, data: any) {
        return axios.put<IRequestResponse>(apiUrl, data);
    }
    delete(apiUrl: string) {
        return axios.delete<IRequestResponse>(apiUrl);
    }

    setToken = (token: string) => {
        axios.defaults.headers['Authorization'] = `Bearer ${token}`;
    };
    clearToken = () => {
        axios.defaults.headers['Authorization'] = null;
    };
}

export const api = new API();
