import React from 'react';
import { Link } from 'react-router-dom';

import {
    Paper,
    Grid,
    Box,
    makeStyles,
    Typography,
    LinearProgress,
    Button,
    withStyles,
    createStyles,
    Theme,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { ISubjectItem } from '../../types/SubjectTypes';
import { transformNormalContentText } from '../../utils/transformNormalContentText';
import EmptyImg from '../UI/EmptyImg';
import { useTranslation } from 'react-i18next';

export interface ISubjectItemProps {
    data: ISubjectItem;
    dense?: boolean;
    showProgress?: boolean;
    progressPlace?: 'content' | 'topImg';
}

const SubjectItem: React.FC<ISubjectItemProps> = ({ data, dense, showProgress, progressPlace = 'content' }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { id, img, title, shortDescription, progress } = data;

    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));
    const isMD = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Link to={`/subjects/${id}`} className={classes.link}>
            <Box mb={{ xxs: 2, lg: 3, xl: 4 }} className={classes.item}>
                <Paper elevation={0} variant="outlined" className={classes.paper}>
                    <Grid container>
                        <Grid item className={classes.imgCol}>
                            <Box display="flex" width="100%" height="100%">
                                {img ? <img src={img} alt="" className={classes.img} /> : <EmptyImg />}
                            </Box>

                            {showProgress && progressPlace === 'topImg' && (
                                <Box className={classes.progressAbsoluteWrap}>
                                    <Box className={classes.progressWrap}>
                                        <Typography variant={isSM ? 'body2' : 'body1'}>
                                            {t("LessonsPassed")} {progress.current}/{progress.all}
                                        </Typography>
                                        <BorderLinearProgress
                                            color="secondary"
                                            variant="determinate"
                                            value={progress.current || progress.all ? (progress.current / progress.all) * 100 : 0}
                                        />
                                    </Box>
                                </Box>
                            )}
                        </Grid>
                        <Grid item className={classes.contentCol}>
                            <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between" p={{ xxs: 2, lg: 3 }}>
                                <Box>
                                    <Box mb={1}>
                                        <Typography variant={isSM ? 'subtitle1' : 'h6'}>{title}</Typography>
                                    </Box>
                                    {!dense && (
                                        <Box mb={{ xxs: 2, sm: 1 }}>
                                            <Typography
                                                variant={isSM ? 'body2' : 'body1'}
                                                color="textSecondary"
                                                component="div"
                                                dangerouslySetInnerHTML={{ __html: transformNormalContentText(shortDescription) }}
                                            />
                                        </Box>
                                    )}

                                    {showProgress && progressPlace === 'content' && (
                                        <Box mb={{ xxs: 2, sm: 1 }}>
                                            <Box className={classes.progressWrap}>
                                                <Typography variant={isSM ? 'body2' : 'body1'}>
                                                {t("LessonsPassed")} {progress.current}/{progress.all}
                                                </Typography>
                                                <BorderLinearProgress
                                                    color="secondary"
                                                    variant="determinate"
                                                    value={(progress.current / progress.all) * 100}
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                </Box>

                                <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                                    {/* <Typography color="textSecondary" variant={isSM ? 'body2' : 'body1'}>
                                        {author}
                                    </Typography> */}

                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size={isMD ? 'small' : 'medium'}
                                        endIcon={<ChevronRightIcon />}
                                        className={classes.watchBtn}
                                    >
                                        {t("Look")}
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Link>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    link: {
        display: 'block',
        width: '100%',
        textDecoration: 'none',
    },
    item: {
        width: '100%',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            maxWidth: 340,
        },
        '&:hover': {
            '& .MuiPaper-root': {
                transform: 'translateY(-2px)',
                borderColor: theme.palette.primary.main,
                boxShadow: '0px 3px 9px rgba(0, 0, 0, 0.08), 0px 1px 22px rgba(0, 0, 0, 0.04), 0px 6px 14px rgba(0, 0, 0, 0.05)',
            },
            '& .MuiButton-root': {
                opacity: 1,
                visibility: 'visible',
                transform: 'translate(0,0)',
            },
        },
    },
    imgCol: {
        position: 'relative',
        width: 270,
        minHeight: 203,
        [theme.breakpoints.down('sm')]: {
            width: 218,
            minHeight: 96,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'top left',
    },
    contentCol: {
        flex: 1,
    },

    paper: {
        transition: 'all .3s ease',
        overflow: 'hidden',
    },
    progressWrap: {
        width: '100%',
        padding: theme.spacing(1, 1),
        borderRadius: 8,
        background: '#FFF0EB',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
        },
    },
    progressAbsoluteWrap: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        padding: '0 16px 13px',
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            padding: '0 8px 8px',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0 13px 13px',
        },
    },
    watchBtn: {
        minWidth: 100,
        [theme.breakpoints.up('lg')]: {
            opacity: 0,
            visibility: 'hidden',
            transition: 'all .3s ease',
            transform: 'translate(2px, 2px)',
        },
        [theme.breakpoints.down('xs')]: {
            width: ' 100%',
        },
    },
}));

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 8,
            borderRadius: 10,
            marginTop: 4,
            [theme.breakpoints.down('sm')]: {
                height: 6,
            },
        },
        colorPrimary: {
            backgroundColor: '#B2DFDB',
        },
        bar: {
            borderRadius: 16,
            backgroundColor: theme.palette.secondary.main,
        },
    }),
)(LinearProgress);

export default SubjectItem;
