import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { makeStyles, Box, Typography, Theme } from '@material-ui/core';

const MenuLink: React.FC<NavLinkProps> = props => {
    const classes = useStyles();

    return (
        <NavLink activeClassName="selected" className={classes.menuLink} {...props}>
            <Box mb={2}>
                <Typography variant="h6" className={classes.linkText}>
                    {props.children}
                </Typography>
            </Box>
        </NavLink>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    menuLink: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
        transition: 'all 0.3s ease',
        '&:hover, &.selected': {
            color: theme.palette.primary.main,
        },
    },
    linkText: {
        [theme.breakpoints.down('xl')]: {
            fontSize: 16,
            lineHeight: '20px',
        },
    },
}));

export default MenuLink;
