// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { action, computed, observable, runInAction } from 'mobx';
import { api } from '../api';
import { ISubjectItem } from '../types/SubjectTypes';
import { IProfileData, IProfileUpdateData } from '../types/ProfileTypes';
import { showNotification } from '../utils/showNotification';
import { transformMySubjectData } from '../utils/dataTransformers';
import { downloadFile } from '../utils/downloadFile';
import { t } from 'i18next';

export class ProfileStore {
    @observable loading = false;
    @observable loadingCertificate = false;
    @observable loadingProfile = false;
    @observable confirmed = false;
    @observable userGetted = false;
    @observable serverError = false;
    @observable errorMessages: any = {};

    @observable updateLoading = {
        userInfo: false,
        email: false,
        password: false,
        notify: false,
    };

    @observable isEdit = {
        userInfo: false,
        email: false,
        password: false,
        notify: false,
    };

    @observable mySubjects: ISubjectItem[] = [];
    @observable profile: IProfileData | null = null;

    @computed
    get totalLessonsCompleted() {
        return this.mySubjects.reduce((acc, curr) => acc + curr.progress.current, 0);
    }

    @computed
    get totalLessonsLeft() {
        const totalLessons = this.mySubjects.reduce((acc, curr) => acc + curr.progress.all, 0);
        return totalLessons - this.totalLessonsCompleted;
    }

    @action
    getProfile = () => {
        this.loadingProfile = true;

        api.get('/auth/user')
            .then(response => {
                if (response.data.success) {
                    runInAction(() => {
                        this.profile = response.data.data;
                        this.confirmed = true;
                    });
                }
            })
            .catch(error => {
                runInAction(() => {
                    if (error.response?.status === 460) {
                        this.confirmed = false;
                        this.userGetted = false;
                    } else {
                        this.serverError = true;
                    }
                });
            })
            .finally(() => {
                runInAction(() => {
                    this.loadingProfile = false;
                });
            });
    };

    @action
    getCertificate = () => {
        this.loadingCertificate = true;

        api.get('/courses/certificate')
            .then(response => {
                if (response.data.success) {
                    runInAction(() => {
                        downloadFile(response.data.data?.url, true);
                    });
                } else {
                    if (response.data.message) {
                        showNotification('info', response.data.message);
                    }
                }
            })
            .catch(error => {
                runInAction(() => {
                    showNotification('danger', error?.message);
                });
            })
            .finally(() => {
                runInAction(() => {
                    this.loadingCertificate = false;
                });
            });
    };

    @action
    updateProfile = (userId: number, body: IProfileUpdateData | FormData, key: 'userInfo' | 'email' | 'password' | 'notify') => {
        this.updateLoading[key] = true;

        api.post(`/users/${userId}`, body)
            .then(response => {
                if (response.data.success) {
                    runInAction(() => {
                        this.profile = response.data.data;
                        this.errorMessages = {};

                        this.isEdit[key] = false;
                    });
                    showNotification('success', 'Профиль уңышлы яңартылды!');
                }
            })
            .catch(error => {
                if (error.response.data?.errors) {
                    runInAction(() => {
                        this.errorMessages = error.response.data.errors;
                    });
                }
            })
            .finally(() => {
                runInAction(() => {
                    this.updateLoading[key] = false;
                });
            });
    };

    @action
    changeEmail = (userId: number, body: IProfileUpdateData | FormData) => {
        this.updateLoading.email = true;

        api.post(`/users/${userId}/change_email`, body)
            .then(response => {
                if (response.data.success) {
                    runInAction(() => {
                        this.errorMessages = {};
                        this.isEdit.email = false;
                    });
                    showNotification('success', t('EmailSuccessChanged'));
                }
            })
            .catch(error => {
                if (error.response.data?.errors) {
                    runInAction(() => {
                        this.errorMessages = error.response.data.errors;
                    });
                }
            })
            .finally(() => {
                runInAction(() => {
                    this.updateLoading.email = false;
                });
            });
    };

    @action
    changePassword = (userId: number, body: IProfileUpdateData | FormData) => {
        this.updateLoading.password = true;

        api.post(`/users/${userId}/change_password`, body)
            .then(response => {
                if (response.data.success) {
                    runInAction(() => {
                        this.errorMessages = {};
                        this.isEdit.password = false;
                    });
                    showNotification('success', t('PasswordChangeSuccess'));
                }
            })
            .catch(error => {
                if (error.response.data?.errors) {
                    runInAction(() => {
                        this.errorMessages = error.response.data.errors;
                    });
                }
            })
            .finally(() => {
                runInAction(() => {
                    this.updateLoading.password = false;
                });
            });
    };

    @action
    removeProfile = (userId: number, key: 'userInfo' | 'email' | 'password', onSuccess: () => void) => {
        this.updateLoading[key] = true;

        api.delete(`/users/${userId}`)
            .then(response => {
                if (response.data.success) {
                    runInAction(() => {
                        this.isEdit[key] = false;
                    });
                    showNotification('success', t('ProfileDeleteSuccess'));
                    onSuccess();
                }
            })
            .catch(error => {
                showNotification('danger', error.response.data?.message);
            })
            .finally(() => {
                runInAction(() => {
                    this.updateLoading[key] = false;
                });
            });
    };

    @action
    setIsEdit = (key: 'userInfo' | 'email' | 'password', state: boolean) => {
        this.isEdit[key] = state;
    };

    @action
    setUserGetted = (state: boolean) => {
        this.userGetted = state;
    };

    // Subjects
    @action
    getMySubjects = () => {
        this.loading = true;

        api.get(`users/my_courses`)
            .then(response => {
                if (response.data.success) {
                    runInAction(() => {
                        this.mySubjects = response.data.data?.map((subject: any) => transformMySubjectData(subject));
                    });
                }
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    };

    @action
    resetStore = () => {
        this.loading = false;
        this.confirmed = false;
        this.userGetted = false;

        this.profile = null;
    };
}
