import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
    mediumEmphasisColor: {
        color: theme.palette.surface.main,
    },
    emailText: {
        overflow: 'hidden',
        maxWidth: '100%',
        textOverflow: 'ellipsis',
    },
}));
