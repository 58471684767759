export const scrollTo = (el?: any, delay = 0) => {
    const headerHeight = document.getElementsByTagName('header')[0].offsetHeight;

    setTimeout(() => {
        window.scrollTo({
            top: el ? el.offsetTop + headerHeight - 40 : 0,
            behavior: 'smooth',
        });
    }, delay);
};
