import { makeStyles, Theme } from '@material-ui/core';
import authFigure from '../../img/auth_figure.svg';

export const useStyles = makeStyles((theme: Theme) => ({
    authPage: {
        display: 'flex',
        flex: '1 1 0%',
        background: `url(${authFigure}) no-repeat center bottom`,
        backgroundSize: '100% auto',
        // overflowX: 'hidden',
        '& .MuiOutlinedInput-root': {
            background: '#fff',
        },
    },
    authRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: 384,
        padding: theme.spacing(4),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        background: '#fff',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: 16,
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2, 4),
            border: 0,
            background: 'transparent',
        },
    },
    logo: {
        width: 112,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            width: 80,
        },
    },
    fullWidth: {
        width: '100%',
    },
    link: {
        textDecoration: 'none',
    },
    agreement: {
        '& a': {
            color: theme.palette.secondary.main,
            transition: 'color .3s ease',
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
    },
}));
