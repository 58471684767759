import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import icon1 from '../../img/about/icon_1.svg';
import icon2 from '../../img/about/icon_2.svg';
import icon3 from '../../img/about/icon_3.svg';

const AboutAdvantagesSection: React.FC = () => {
    const { t } = useTranslation();
    return (
        <Grid container spacing={4}>
            <AdvantagItem icon={icon1} title={t("AboutAdvantagesSectionTitle1")}>
                {t("AboutAdvantagesSectionText1")}
            </AdvantagItem>
            <AdvantagItem icon={icon2} title={t("AboutAdvantagesSectionTitle2")}>
                {t("AboutAdvantagesSectionText2")}
            </AdvantagItem>
            <AdvantagItem icon={icon3} title={t("AboutAdvantagesSectionTitle3")}>
                {t("AboutAdvantagesSectionText3")}
            </AdvantagItem>
        </Grid>
    );
};

interface AdvantagItemProps {
    icon: string;
    title: string;
    children: string;
}

const AdvantagItem: React.FC<AdvantagItemProps> = ({ icon, title, children }) => {
    return (
        <Grid item xs={12} md={6} lg={4}>
            <Box mb={{ xxs: 3, md: 4, lg: 0 }}>
                <Box mb={{ xxs: 2, md: 3 }} maxWidth={{ xxs: 80, md: 90, lg: 116 }}>
                    <img src={icon} alt="" style={{ width: '100%' }} />
                </Box>
                <Box mb={{ xxs: 1, md: 2 }}>
                    <Typography variant="h6">{title}</Typography>
                </Box>
                <Box maxWidth={{ xxs: 320, sm: 400 }}>
                    <Typography color="textSecondary">{children}</Typography>
                </Box>
            </Box>
        </Grid>
    );
};

export default AboutAdvantagesSection;
