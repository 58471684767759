export const downloadFile = (url: string | undefined | null, target?: boolean) => {
    if (url?.length) {
        const link = document.createElement('a');

        if (target) {
            link.setAttribute('target', '_blank');
        }

        link.href = url;
        link.click();
    }
    return;
};
