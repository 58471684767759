import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import translationRu from '../locales/ru/translation.json';
import translationTt from '../locales/tt/translation.json';

// the translations
const resources = {
  ru: {
    translation: translationRu
  },
  tt: {
    translation: translationTt
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: window.localStorage.defaultLanguage ?? "tt",
    fallbackLng: "tt",


    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;