import React, { FC, useEffect, useLayoutEffect, useState } from "react";
import {
  IRutubeData,
  IRutubeMessage,
  RutubeMessageTypes,
  RutubePlayerStates,
} from "types/RutubeTypes";

interface IRutubePlayerProps {
  id: string;
  title?: string;
  size?: {
    width: number;
    height: number;
  };

  onPlay?: () => void;
  onProgressPercentChange?: (currentPercent: number) => void;
}

const RUTUBE_URL = "https://rutube.ru";

const RutubePlayer: FC<IRutubePlayerProps> = (props) => {
  const { id, size, title } = props;

  const [videoDuration, setVideoDuration] = useState(0);
  const [videoCurrentTime, setVideoCurrentTime] = useState(0);

  // Handlers
  const handleDurationChange = (data: IRutubeData) => {
    setVideoDuration(data.duration || 0);
  };

  const handleCurrentTimeChange = (data: IRutubeData) => {
    setVideoCurrentTime(data.time || 0);
  };

  // Effects
  useLayoutEffect(() => {
    const handleWindowMessage = (e: MessageEvent) => {
      const isRutube = e.origin === RUTUBE_URL;

      if (!isRutube) {
        return;
      }

      const rutubeMessage = JSON.parse(e.data) as IRutubeMessage;

      switch (rutubeMessage.type) {
        case RutubeMessageTypes.INIT:
        case RutubeMessageTypes.READY:
        case RutubeMessageTypes.ERROR:
        case RutubeMessageTypes.BUFFERING:
        case RutubeMessageTypes.ROLL_STATE:
        case RutubeMessageTypes.PLAY_COMPLETE:
        case RutubeMessageTypes.VOLUME_CHANGE:
        case RutubeMessageTypes.CURRENT_QUALITY:
        case RutubeMessageTypes.CHANGE_FULLSCREEN:
        case RutubeMessageTypes.PLAY_OPTIONS_LOADED:
          break;

        case RutubeMessageTypes.CHANGE_STATE:
          if (rutubeMessage.data.state !== RutubePlayerStates.PLAYING) {
            break;
          }
          props.onPlay?.();
          break;

        case RutubeMessageTypes.DURATION_CHANGE:
          handleDurationChange(rutubeMessage.data);
          break;

        case RutubeMessageTypes.CURRENT_TIME:
          handleCurrentTimeChange(rutubeMessage.data);
          break;

        default:
          break;
      }
    };

    window.addEventListener("message", handleWindowMessage);

    return () => window.removeEventListener("message", handleWindowMessage);
  }, [props.onPlay]);

  useEffect(() => {
    if (!videoDuration) {
      return;
    }

    const progressPercent = (videoCurrentTime / videoDuration) * 100;

    props.onProgressPercentChange?.(progressPercent);
  }, [videoDuration, videoCurrentTime, props.onProgressPercentChange]);

  // Renders
  return (
    <iframe
      width={size?.width || 740}
      height={size?.height || 416}
      src={`https://rutube.ru/play/embed/${id}`}
      allowFullScreen
      title={title || "rutube"}
      style={{ border: "none" }}
    />
  );
};

export default RutubePlayer;
