// https://rutube.ru/info/embed/#embed009 описание событий взяты тут
export enum RutubeMessageTypes {
  INIT = "player:init",
  READY = "player:ready",
  ERROR = "player:error",
  BUFFERING = "player:buffering",
  ROLL_STATE = "player:rollState",
  CHANGE_STATE = "player:changeState",
  CURRENT_TIME = "player:currentTime",
  PLAY_COMPLETE = "player:playComplete",
  VOLUME_CHANGE = "player:volumeChange",
  CURRENT_QUALITY = "player:currentQuality",
  DURATION_CHANGE = "player:durationChange",
  CHANGE_FULLSCREEN = "player:changeFullscreen",
  PLAY_OPTIONS_LOADED = "player:playOptionsLoaded",
}

export enum RutubePlayerStates {
  PLAYING = "playing",
  PAUSED = "paused",
  STOPPED = "stopped",
  LOCK_SCREEN_ON = "lockScreenOn",
  LOCK_SCREEN_OFF = "lockScreenOff",
}

export interface IRutubeData {
  playerId: string;
  [key: string]: any;
}

export interface IRutubeMessage {
  type: RutubeMessageTypes;
  data: IRutubeData;
}
