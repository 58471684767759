import React from 'react';
import { Box, Typography, Grid, useTheme, useMediaQuery, Theme, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import img1_tt from '../../img/about/training-steps/img1.png';
import img1_ru from '../../img/about/training-steps/img1_ru.jpg';
import img2_tt from '../../img/about/training-steps/img2.png';
import img2_ru from '../../img/about/training-steps/img2_ru.jpg';
import img3 from '../../img/about/training-steps/img3.png';
import { isRu } from '../../utils/isRu';

const TrainingStepsSection: React.FC = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));
    const img1 = isRu ? img1_ru : img1_tt;
    const img2 = isRu ? img2_ru : img2_tt;

    return (
        <>
            <Box mb={{ xxs: 8, md: 12 }}>
                <Typography align="center" variant={isSM ? 'h4' : 'h3'}>
                {t("TrainingTheme")}:
                </Typography>
            </Box>

            <StepItem img={img1} title={t("TrainingThemeSignInTitle")}>
                {t("TrainingThemeSignInText")}
            </StepItem>

            <StepItem img={img2} title={t("TrainingThemeOnlineClassesTitle")} reverse>
                {t("TrainingThemeOnlineClassesText")}
            </StepItem>

            <StepItem img={img3} title={t("TrainingThemeTestCheckTitle")}>
                {t("TrainingThemeTestCheckText")}
            </StepItem>
        </>
    );
};

interface StepItemSectionProps {
    img: string;
    title: string;
    children: any;
    reverse?: boolean;
}

const StepItem: React.FC<StepItemSectionProps> = ({ title, img, children, reverse }) => {
    const classes = useStyles();

    return (
        <Box mb={{ xxs: 5, md: 7, xl: 11 }}>
            <Grid container spacing={4}>
                <Grid container item xs={12} md={6} direction="column" justifyContent="center" className={reverse ? classes.order2 : ''}>
                    <Box className={reverse ? classes.reverseContentBox : classes.contentBox}>
                        <Box mb={{ xxs: 2, md: 3 }}>
                            <Typography variant="h5">{title}</Typography>
                        </Box>
                        <Typography color="textSecondary">{children}</Typography>
                    </Box>
                </Grid>
                <Grid container item xs={12} md={6} justifyContent="center" className={reverse ? classes.order1 : ''}>
                    <img src={img} alt="" style={{ width: '100%' }} />
                </Grid>
            </Grid>
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    order1: {
        [theme.breakpoints.up('md')]: {
            order: 1,
        },
    },
    order2: {
        [theme.breakpoints.up('md')]: {
            order: 2,
        },
    },
    contentBox: {
        paddingRight: theme.spacing(9),
        [theme.breakpoints.down('lg')]: {
            paddingRight: theme.spacing(6),
        },
        [theme.breakpoints.down('md')]: {
            paddingRight: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
            paddingRight: theme.spacing(0),
        },
    },
    reverseContentBox: {
        paddingLeft: theme.spacing(9),
        [theme.breakpoints.down('lg')]: {
            paddingLeft: theme.spacing(6),
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(0),
        },
    },
}));

export default TrainingStepsSection;
