import queryString from 'query-string';
import { showNotification } from '../utils/showNotification';

const _socialCodeStorageKey = 'huzurSocialCode';
const _authWindowStatusStorageKey = 'huzurSocialAuthWindowStatus';

type StorageKey = typeof _socialCodeStorageKey | typeof _authWindowStatusStorageKey;

class SocialAuthService {
    loginRequestSended = false;
    handler: any = null;

    openAuthWindow = (url: string) => {
        const width = window.screen.width / 2;
        const height = window.screen.height / 2;
        const offsetLeft = window.screenLeft || window.screenX || 0;
        const offsetTop = window.screenTop || window.screenY || 0;

        const left = width / 2 + offsetLeft;
        const top = height / 2 + offsetTop;

        this.setData(_authWindowStatusStorageKey, 'opened');
        window.open(url, 'Авторизация Хузур', `top=${top},left=${left},width=${width},height=${height},resizable,scrollbars,status`);

        // window.open(
        //     'http://localhost:3001/login?code=ss',
        //     'Авторизация Хузур',
        //     `top=${top},left=${left},width=${width},height=${height},resizable,scrollbars,status`,
        // );
    };

    setCode = (query: string) => {
        const { code } = queryString.parse(query);
        this.removeData(_socialCodeStorageKey);

        if (code?.length) {
            this.setData(_socialCodeStorageKey, code as string);
            setTimeout(() => {
                this.removeData(_authWindowStatusStorageKey);
                window.close();
            }, 100);
        } else {
            setTimeout(() => {
                if (!!this.getData(_authWindowStatusStorageKey)) {
                    this.setData(_authWindowStatusStorageKey, 'closed');
                    window.close();
                }
            }, 100);
        }
    };

    storageEventHandler = (e: any, onLogin?: (code: string) => void) => {
        if (e.key === _socialCodeStorageKey) {
            const code = this.getData(_socialCodeStorageKey);

            if (code) {
                setTimeout(() => {
                    if (onLogin) {
                        onLogin(code);
                    }
                    this.removeData(_socialCodeStorageKey);
                }, 100);
            }
        }

        if (e.key === _authWindowStatusStorageKey) {
            if (this.getData(_authWindowStatusStorageKey) === 'closed') {
                this.removeData(_authWindowStatusStorageKey);
                showNotification('danger', 'Что-то пошло не так');
            }
        }
    };

    storageEventListener = (onLogin: (code: string) => void) => {
        this.handler = (e: any) => this.storageEventHandler(e, onLogin);

        window.addEventListener('storage', this.handler, false);
    };

    clearListeners = () => {
        window.removeEventListener('storage', this.handler, false);
    };

    setData = (key: StorageKey, value: string) => {
        return localStorage.setItem(key, value);
    };
    getData = (key: StorageKey) => {
        return localStorage.getItem(key);
    };
    removeData = (key: StorageKey) => {
        return localStorage.removeItem(key);
    };
}

export default new SocialAuthService();
