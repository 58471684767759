import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { InlineIcon } from '@iconify/react';
import googleIcon from '@iconify/icons-logos/google-icon';
import vkIcon from '@iconify/icons-simple-icons/vk';
import odnoklassnikiRect from '@iconify/icons-brandico/odnoklassniki-rect';

import { Provider } from '../../types/AuthTypes';
import { useStores } from '../../hooks/useStores';

export interface SocialBtnTypes {
    provider: 'email' | Provider;
    children: string;
}

const SocialBtn: React.FC<SocialBtnTypes> = observer(({ provider, children }) => {
    const classes = useStyles();
    const { AuthStore: store } = useStores();
    const history = useHistory();

    const handleClick = () => {
        if (provider === 'email') {
            if (history.location.pathname.includes('login')) {
                history.push('/login/email');
            } else {
                history.push('/register/email');
            }
        } else {
            store.getSocialAuthUrl(provider as Provider);
        }
    };

    const getIcon = () => {
        switch (provider) {
            case 'google':
                return <InlineIcon icon={googleIcon} />;
            case 'vkontakte':
                return <InlineIcon icon={vkIcon} color="#4A76A8" />;
            case 'odnoklassniki':
                return <InlineIcon icon={odnoklassnikiRect} color="#F7931E" />;

            default:
                return <MailOutlineIcon />;
        }
    };

    return (
        <Button startIcon={getIcon()} variant="contained" fullWidth className={classes.btn} onClick={handleClick}>
            {children}
        </Button>
    );
});

const useStyles = makeStyles(theme => ({
    btn: {
        height: 40,
        justifyContent: 'flex-start',
        marginBottom: theme.spacing(2),
        background: '#fff',
        transition: 'all .3s ease',
        boxShadow: '0px 1px 9px rgba(0, 0, 0, 0.08), 0px 3px 8px rgba(0, 0, 0, 0.04), 0px 2px 8px rgba(0, 0, 0, 0.05)',
        '&:hover': {
            backgroundColor: '#fff',
            transform: 'translateY(-1px)',
            boxShadow: '0px 1px 9px rgba(0, 0, 0, 0.1), 0px 3px 8px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.1)',
        },
        '& .MuiSvgIcon-root': {
            fontSize: 22,
            color: theme.palette.surface.main,
        },
        '& .MuiButton-startIcon': {
            display: 'flex',
            justifyContent: 'center',
            width: 22,
        },
    },
}));

export default SocialBtn;
