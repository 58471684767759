import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import Loader from './Loader';

interface ILoaderButtonProps extends ButtonProps {
    loading: boolean;
}

const LoaderButton: React.FC<ILoaderButtonProps> = props => {
    return <Button {...props}>{props.loading ? <Loader size={24} color="#fff" /> : props.children}</Button>;
};

export default LoaderButton;
