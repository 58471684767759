import { ISubjectLesson } from '../../types/SubjectTypes';

export const getProgramStatus = (completed: boolean, lessons: ISubjectLesson[], nextAvailableProgram?: boolean) => {
    if (lessons.length) {
        if (completed) {
            return 'done';
        } else {
            const startedLessons = lessons.filter((lesson: any) => {
                return lesson.started;
            });

            return startedLessons.length || nextAvailableProgram ? 'progress' : 'notAvailable';
        }
    }

    return 'notAvailable';
};
