import findLastIndex from 'lodash/findLastIndex';
import { ISubjectLesson } from '../../types/SubjectTypes';

export const getControlledStatusLessons = (lessons: ISubjectLesson[], nextAvailableProgram?: boolean) => {
    if (!lessons.length) {
        return [];
    }

    const lastStartedLessonIndex = findLastIndex(lessons, (lesson: ISubjectLesson) => lesson.started);
    const getLessonStatus = (lesson: ISubjectLesson, index: number) => {
        if (lastStartedLessonIndex !== -1) {
            const nextLessonIndex = lastStartedLessonIndex + 1;

            if (lesson.completed) {
                return 'done';
            }
            if (lesson.started) {
                return 'progress';
            }

            if (lessons[lastStartedLessonIndex].completed && index === nextLessonIndex) {
                return 'available';
            }

            return 'notAvailable';
        }

        if (nextAvailableProgram && index === 0) {
            return 'available';
        }

        return 'notAvailable';
    };

    return lessons.map((lesson: ISubjectLesson, index: number) => {
        return {
            ...lesson,
            status: getLessonStatus(lesson, index),
        };
    });
};
