import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useStyles } from '../../components/Auth/style';
import { useTranslation } from 'react-i18next';

const AgreementText: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Box maxWidth={240} textAlign="center">
            <Typography variant="body2" className={classes.agreement}>
                {t("AgreementTextScreen1")}<Link to="/policy">{t("AgreementTextScreen2")}</Link>{t("AgreementTextScreen3")}
            </Typography>
        </Box>
    );
};

export default AgreementText;
