import React from 'react';
import { Box, Typography, makeStyles, Theme } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import InfoIcon from '@material-ui/icons/Info';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { IAdditionalMaterialItemProps } from '../../types/LessonTypes';

const AdditionalMaterialItem: React.FC<IAdditionalMaterialItemProps> = ({ type, children, link }) => {
    const classes = useStyles();

    const renderIcon = () => {
        switch (type) {
            case 'file':
                return <InsertDriveFileOutlinedIcon className={classes.icon} />;
            case 'link':
                return <LinkIcon className={classes.icon} />;

            default:
                return <InfoIcon className={classes.icon} />;
        }
    };

    return (
        <Box mb={{ xxs: 1, lg: 2 }}>
            <a href={link} className={classes.link} target="_blank" rel="noopener noreferrer">
                <Box className={classes.iconWrap}>{renderIcon()}</Box>
                <Typography>{children}</Typography>
            </a>
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    link: {
        display: 'inline-flex',
        alignItems: 'center',
        color: theme.palette.surface.main,
        textDecoration: 'none',
        paddingRight: theme.spacing(2),
        transition: 'all .3s ease',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    iconWrap: {
        width: 48,
        height: 48,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(2),
        borderRadius: '8px',
        backgroundColor: '#FFF0EB',
    },
    icon: {
        fontSize: 24,
        color: theme.palette.surface.main,
    },
}));

export default AdditionalMaterialItem;
