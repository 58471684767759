import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  makeStyles,
  Theme,
  Button,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import AppleIcon from "@material-ui/icons/Apple";
import { InlineIcon } from "@iconify/react";
import logoGooglePlaystore from "@iconify/icons-ion/logo-google-playstore";
import { useTranslation } from "react-i18next";

import MenuLink from "./MenuLink";
import logo from "img/logo.png";
import vk from "img/socials/vk.svg";
import ok from "img/socials/ok.svg";
import tg from "img/socials/tg.svg";
import call from "img/call.svg";
import envelope from "img/envelope.svg";

import firstLogo from "img/footer/first-logo.svg";
import secondLogo from "img/footer/second-logo.svg";
import secondLogoRu from "img/footer/second-logo-ru.svg";
import { isRu } from "utils/isRu";

const Footer: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  const dumrtLogo = isRu ? secondLogoRu : secondLogo;

  // Renders
  return (
    <div className={classes.root}>
      <Container maxWidth="xl">
        <Box mb={7}>
          <Grid container spacing={2}>
            <Grid
              container
              item
              xs={12}
              md={3}
              lg={2}
              justifyContent={isMD ? "center" : "flex-start"}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                width="100%"
                maxWidth={184}
                mb={{ xxs: 4, md: 0 }}
              >
                <Box mt={-3} mb={1}>
                  <img src={logo} alt="" className={classes.logo} />
                </Box>
                <Box mb={3}>
                  <Typography className={classes.logoText}>
                    {t("TatarMedrese")}
                  </Typography>
                </Box>
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                >
                  <SocialItem link="https://vk.com/tatarmedrese" icon={vk} />
                  <SocialItem
                    link="https://ok.ru/group/65043623247916"
                    icon={ok}
                  />
                  <SocialItem link="https://t.me/tatmedrese" icon={tg} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Box pl={{ xxs: 0, lg: 4 }}>
                <MenuLink to="/subjects">{t("TrainingTheme")}</MenuLink>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} lg={5}>
              <Box pl={{ lg: 6, xl: 10 }} mt={{ xxs: -2, md: 0 }}>
                <MenuLink to="/about">{t("AboutProject")}</MenuLink>
                <MenuLink to="/policy">{t("Confidencial")}</MenuLink>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                gridGap={16}
                pl={{ lg: 6, xl: 10 }}
              >
                <Typography
                  component="a"
                  variant="h6"
                  href="tel:+78435980946"
                  className={classes.contactLinks}
                >
                  <img src={call} alt="phone" />
                  +7 (843) 598-09-46
                </Typography>
                <Typography
                  component="a"
                  variant="h6"
                  href="mailto:it@huzur.ru"
                  className={classes.contactLinks}
                >
                  <img src={envelope} alt="envelope" />
                  it@huzur.ru
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <Box mt={{ xxs: 2, lg: 0 }} mb={2}>
                <Typography color="textSecondary">
                  {t("Description")}
                </Typography>
              </Box>
              <Grid
                container
                spacing={2}
                justifyContent={isMD ? "center" : "flex-start"}
              >
                <Grid item xs={12} md={6} lg={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<AppleIcon />}
                    className={classes.appBtn}
                    href="https://apps.apple.com/us/app/%D1%82%D0%B0%D1%82%D0%B0%D1%80-%D0%BC%D3%99%D0%B4%D1%80%D3%99%D1%81%D3%99%D1%81%D0%B5/id1537946121"
                    target="_blank"
                  >
                    iOS
                  </Button>
                </Grid>
                <Grid item xs={12} md={6} lg={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<InlineIcon icon={logoGooglePlaystore} />}
                    className={classes.appBtn}
                    href="https://play.google.com/store/apps/details?id=ru.huzur.app"
                    target="_blank"
                  >
                    Android
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box pt={4} className={classes.bottomLogoWrap}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mr={{ xxs: 2, sm: 3, md: 7.5 }}
            width={{ xxs: 55, md: 72 }}
            height={{ xxs: 55, md: 72 }}
          >
            <img className={classes.bottomLogo} src={firstLogo} alt="" />
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={{ xxs: 226, md: 285 }}
            height={{ xxs: 49, md: 62 }}
          >
            <img className={classes.bottomLogo} src={dumrtLogo} alt="" />
          </Box>
        </Box>
      </Container>
    </div>
  );
};

interface ISocialItemProps {
  link: string;
  icon: string;
}

const SocialItem: React.FC<ISocialItemProps> = ({ link, icon }) => {
  const classes = useStyles();

  // Renders
  return (
    <a
      href={link}
      target="_blank"
      className={classes.socialLink}
      rel="noopener noreferrer"
    >
      <img src={icon} alt="" className={classes.socialIcon} />
    </a>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(11, 0),
    backgroundColor: "#FFF0EB",
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(9, 0, 6),
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(8, 0, 5),
    },
  },
  logo: {
    width: 88,
    [theme.breakpoints.down("xs")]: {
      width: 70,
    },
  },
  logoText: {
    fontSize: 14,
    lineHeight: "24px",
    fontWeight: 700,
    textTransform: "uppercase",
  },
  socialLink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  socialIcon: {},
  appBtn: {
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      width: 250,
    },
  },
  bottomLogoWrap: {
    display: "flex",
    alignItems: "center",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  bottomLogo: {
    width: "100%",
    objectFit: "cover",
  },
  contactLinks: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    fontSize: theme.spacing(2),
    textDecoration: "none",
    color: theme.palette.text.primary,
    transition: "all 0.3s ease",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

export default Footer;
