// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { action, observable, runInAction } from 'mobx';
import { INewsItem, INewsData } from '../types/NewsTypes';
import { api } from '../api';

export class NewsStore {
    @observable loading = true;
    @observable newsList: INewsItem[] = [];
    @observable newsData: INewsData | null = null;

    @action
    getNewsList = () => {
        this.loading = true;

        api.get('/news')
            .then(response => {
                if (response.data.success) {
                    runInAction(() => {
                        this.newsList = response.data.data.data;
                    });
                }
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    };

    @action
    getNews = (newsId: number) => {
        this.loading = true;

        api.get(`/news/${newsId}`)
            .then(response => {
                if (response.data.success) {
                    runInAction(() => {
                        this.newsData = response.data.data;
                    });
                }
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    };
}
