import React from 'react';
import { makeStyles, Button, ButtonProps } from '@material-ui/core';
import clsx from 'clsx';

const TextButton: React.FC<ButtonProps> = props => {
    const classes = useStyles();

    return (
        <Button variant="text" disableRipple className={clsx(classes.button, classes[props.color || 'primary'])} {...props}>
            {props.children}
        </Button>
    );
};

const useStyles = makeStyles(theme => ({
    button: {
        padding: 0,
        minWidth: 'auto',
        transition: 'all ease 0.3s',
    },
    primary: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.light,
            backgroundColor: 'transparent',
        },
        '&:active': {
            color: theme.palette.primary.dark,
        },
    },
    secondary: {},
    inherit: {},
    default: {
        color: theme.palette.surface.light,
        '&:hover': {
            color: theme.palette.surface.main,
            backgroundColor: 'transparent',
        },
        '&:active': {
            color: theme.palette.surface.dark,
        },
    },
}));

export default TextButton;
